import {
  concat,
  flatten,
  includes,
  isArray,
  isEmpty,
  isEqual,
  join,
  keys,
  map,
  mergeWith,
  reduce,
  size,
  uniq,
  without,
} from 'lodash';
import {
  CreditActivityAction,
  CreditActivityContext,
  CreditsActivityFilter,
  ExportDestination,
  exportDestinations,
  ModelType,
  TimePeriod,
} from 'types';
import { timePeriod } from './Filters';

export type SelectType = Array<
  'all' | ModelType | UICreditActivityAction | CreditActivityContext | TimePeriod
>;

export enum UICreditActivityAction {
  AdminAddRemove = 'admin_add_remove',
  ExportCRM = 'export_crm',
  ExportCSV = 'export_csv',
  Reveal = 'reveal',
}

export const creditActivityActions = {
  [UICreditActivityAction.Reveal]: 'Show info',
  [UICreditActivityAction.ExportCSV]: 'Export CSV',
  [UICreditActivityAction.ExportCRM]: 'Export CRM',
  [UICreditActivityAction.AdminAddRemove]: 'Klarity admin',
};

export const creditActivityRecordTypes = {
  [ModelType.Company]: 'Company',
  [ModelType.Contact]: 'Contact',
};

export const creditActivityContexts = {
  [CreditActivityContext.Webapp]: 'Webapp',
  [CreditActivityContext.Extension]: 'Extension',
  [CreditActivityContext.Admin]: 'Admin',
};

const LABELS: Record<string, string> = {
  ...creditActivityRecordTypes,
  ...creditActivityActions,
  ...creditActivityContexts,
  ...timePeriod,
  custom: 'Custom',
};

export const csvDestinations: ExportDestination[] = ['csv'];

export const crmDestnations: ExportDestination[] = without(
  keys(exportDestinations),
  ...csvDestinations,
) as ExportDestination[];

const mergeCustomizer = (objValue: any, srcValue: any) => {
  if (isArray(objValue)) {
    return uniq(concat(objValue, srcValue));
  }

  return undefined;
};

export const mergeCreditsActivityFilter = (
  object: CreditsActivityFilter,
  source: CreditsActivityFilter,
) => mergeWith(object, source, mergeCustomizer);

export const getSelectLabel = (value: any[]) => {
  if (isEqual(value, ['all'])) {
    return 'All credits logs';
  }

  return join(
    map(value, (key: string) => LABELS[key]),
    ', ',
  );
};

const getUICreditActivityAction = (
  actions: CreditActivityAction[],
  destinations?: ExportDestination[],
): UICreditActivityAction[] => {
  const res = uniq(
    map(actions, (action) => {
      if (action === CreditActivityAction.AdminAdd || action === CreditActivityAction.AdminRemove) {
        return UICreditActivityAction.AdminAddRemove;
      } else if (action === CreditActivityAction.Export) {
        let exportActions: UICreditActivityAction[] = [];
        if (includes(destinations, 'csv')) {
          exportActions = [...exportActions, UICreditActivityAction.ExportCSV];
        }
        if (size(destinations) > 1) {
          exportActions = [...exportActions, UICreditActivityAction.ExportCRM];
        }

        return exportActions;
      }

      return UICreditActivityAction.Reveal;
    }),
  );

  return flatten(res);
};

export const getSelectValue = (value?: CreditsActivityFilter): SelectType => {
  if (
    isEmpty(value?.record_types) &&
    isEmpty(value?.actions) &&
    isEmpty(value?.action_contexts) &&
    isEmpty(value?.time_period) &&
    isEmpty(value?.date_from && value?.date_to)
  ) {
    return ['all'];
  }

  let res: SelectType = [];

  if (value?.record_types) {
    res = [...res, ...value.record_types];
  }
  if (value?.actions) {
    const actions = getUICreditActivityAction(value.actions, value.destinations);

    res = [...res, ...actions];
  }
  if (value?.action_contexts) {
    res = [...res, ...value.action_contexts];
  }
  if (value?.time_period) {
    res = [...res, value.time_period];
  }
  if (value?.date_from && value?.date_to) {
    res = [...res, 'custom'];
  }

  return res;
};

export const getFilterValue = (value: SelectType) =>
  reduce(
    value,
    (res, val) => {
      if (val === 'all') {
        return res;
      }

      if (includes(CreditActivityContext, val)) {
        return mergeCreditsActivityFilter(res, {
          action_contexts: [val as CreditActivityContext],
        });
      } else if (includes(ModelType, val)) {
        return mergeCreditsActivityFilter(res, {
          record_types: [val as ModelType],
        });
      } else if (includes(UICreditActivityAction, val)) {
        if (val === UICreditActivityAction.AdminAddRemove) {
          return mergeCreditsActivityFilter(res, {
            actions: [CreditActivityAction.AdminAdd, CreditActivityAction.AdminRemove],
          });
        } else if (val === UICreditActivityAction.ExportCSV) {
          return mergeCreditsActivityFilter(res, {
            actions: [CreditActivityAction.Export],
            destinations: csvDestinations,
          });
        } else if (val === UICreditActivityAction.ExportCRM) {
          return mergeCreditsActivityFilter(res, {
            actions: [...(res.actions || []), CreditActivityAction.Export],
            destinations: crmDestnations,
          });
        } else if (val === UICreditActivityAction.Reveal) {
          return mergeCreditsActivityFilter(res, {
            actions: [CreditActivityAction.Reveal],
          });
        }
      } else if (includes(Object.keys(timePeriod), val)) {
        return mergeCreditsActivityFilter(res, {
          time_period: val as TimePeriod,
        });
      }

      return res;
    },
    {} as CreditsActivityFilter,
  );
