import { FC, useCallback } from 'react';
import { Pagination } from '@demandscience/ui';

export interface SimplePaginationProps {
  count: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  totalPage?: number | undefined;
}

const SimplePagination: FC<SimplePaginationProps> = ({ page, setPage, pageSize, count = 0 }) => {
  const handleChange = useCallback(
    (_: any, value: number) => {
      // setting a vault limit on Pagination to show upto 10,000 records
      if (value * pageSize > 10000) return;
      setPage(value - 1);
    },
    [pageSize, setPage],
  );

  return (
    <Pagination.Outlined
      size="xs"
      page={page + 1}
      count={count}
      rowsPerPage={pageSize}
      onPageChange={handleChange}
      showPages={1}
    />
  );
};

export default SimplePagination;
