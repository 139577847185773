import api from 'api';
import axios from 'axios';
import fileDownload from 'js-file-download';

import {
  Organization,
  Member,
  InviteResult,
  ReportedBouncesListPayload,
  BouncesListResponse,
  MessagePayload,
  MonthlyActivityResponse,
  InvitePayload,
  SearchMembersParams,
  FetchMembersParams,
  UpdateMemberPayload,
  MembersResponse,
  NotificationMessagePayload,
  SearchCreditsActivityParams,
  CreditsActivityResponse,
  AcknowledgeSubscriptionPayload,
  SubscriptionPlansResponse,
  BillingResponse,
  InvoiceDownloadResponse,
  BillingParams,
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
  BillingInfoPayload,
  OrganizationInfoPayload,
  OrganizationRequestFeaturePayload,
  SearchParams,
  CreditsActivityFilter,
} from 'types';

export const fetchCurrent = (): Promise<Organization> => api.get('/organization');

export const inviteMembers = (payload: InvitePayload): Promise<InviteResult[]> =>
  api.post('/organization/invite', payload);

export const searchMembers = (params: SearchMembersParams): Promise<MembersResponse> =>
  api.post('/organization/member/search', params);

export const fetchMembers = (params: FetchMembersParams): Promise<MembersResponse> =>
  api.post('/organization/member', params);

export const fetchMember = (id: string): Promise<Member> => api.get(`/organization/member/${id}`);

export const deleteMember = (id: string): Promise<void> => api.delete(`/organization/member/${id}`);

export const updateMember = ({ id, ...data }: Partial<UpdateMemberPayload>) =>
  api.patch(`/organization/member/${id}`, data);

export const reactivateMember = (id: string): Promise<void> =>
  api.patch(`/organization/member/${id}/reinstate`);

export const suspendMember = (id: string): Promise<void> =>
  api.patch(`/organization/member/${id}/suspend`);

export const resendMemberInvite = (id: string): Promise<void> =>
  api.patch(`/organization/member/${id}/resend_invite`);

export const acceptCreditsRequest = ({
  id,
  ...payload
}: {
  id: string;
} & NotificationMessagePayload): Promise<void> =>
  api.post(`/organization/notification/${id}/credit/grant`, payload);

export const rejectCreditsRequest = ({
  id,
  ...payload
}: {
  id: string;
} & NotificationMessagePayload): Promise<void> =>
  api.post(`/organization/notification/${id}/credit/reject`, payload);

export const fetchBouncesList = (
  params: ReportedBouncesListPayload,
): Promise<BouncesListResponse> => api.post('/organization/bounce/search', params);

export const acquireExtraCredits = (payload: MessagePayload): Promise<void> =>
  api.post('/organization/credits/acquire_extra', payload);

export const changePlan = (payload: MessagePayload): Promise<void> =>
  api.post('/organization/subscription_plan/change', payload);

export const fetchMonthlyActivity = (): Promise<MonthlyActivityResponse> =>
  api.get('/organization/activity/monthly');

export const searchCreditsActivity = (
  payload: SearchCreditsActivityParams,
): Promise<CreditsActivityResponse> => api.post('/organization/credits/activity', payload);

export const acknowledgeSubscription = (payload: AcknowledgeSubscriptionPayload): Promise<void> =>
  api.post('/organization/subscription/acknowledge', payload);

export const fetchPlans = (): Promise<SubscriptionPlansResponse> => api.get('/organization/plan');

export const createSubscription = (
  payload: CreateSubscriptionPayload,
): Promise<CreateSubscriptionResponse> => api.post('/organization/subscription', payload);

export const fetchBilling = (payload: BillingParams): Promise<BillingResponse> =>
  api.post('/organization/billing/invoice', payload);

export const downloadInvoice = async (id: string, filename: string): Promise<void> => {
  const { url }: InvoiceDownloadResponse = await api.get(
    `/organization/billing/invoice/${id}/download`,
  );
  const { data } = await axios.get(url, { responseType: 'blob' });
  fileDownload(data, filename);
};

export const fetchBillingInfo = (): Promise<BillingInfoPayload> =>
  api.get('/organization/self-service/customer');

export const updateCurrentBillingEmail = (payload: BillingInfoPayload): Promise<void> =>
  api.patch('/organization/self-service/customer', payload);

export const updateOrganization = (payload: OrganizationInfoPayload): Promise<void> =>
  api.patch(`/organization`, payload);

export const requestFeatures = (payload: OrganizationRequestFeaturePayload): Promise<void> =>
  api.post('/organization/feature-request', payload);

export const downloadTeam = async (): Promise<void> => {
  const { data: url } = await api.get(`/organization/member/activity/download`);
  const { data } = await axios.get(url, { responseType: 'blob' });

  fileDownload(data, 'Team Download.csv');
};
export const downloadReport = async (
  params: SearchParams<CreditsActivityFilter>,
): Promise<void> => {
  const { data: url } = await api.post(`/organization/credits/activity/download`, params);
  const { data } = await axios.get(url, { responseType: 'blob' });

  fileDownload(data, 'Report Download.csv');
};
