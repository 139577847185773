import { truncate } from 'lodash';
import { AccountBase, Notification } from 'types';
import { getRecordsLabel } from 'utils/string';

const getCreditNotificationTitle = (notification: Notification) => {
  if (notification.event === 'request') {
    return 'requested more credits';
  } else if (notification.event === 'grant') {
    return 'approved your request for credits';
  } else if (notification.event === 'reject') {
    return 'rejected your request for credits';
  } else if (notification.event === 'decrease') {
    return notification.message;
  } else if (notification.event === 'expiry') {
    return notification.message;
  }

  return null;
};

const getListNotificationTitle = (notification: Notification) => {
  if (notification.event === 'share') {
    return 'shared a list with you';
  } else if (notification.event === 'delete') {
    return `has deleted this list ${notification.details?.list_name || ''}`;
  }

  return null;
};

const getSavedSearchNotificationTitle = (notification: Notification) => {
  if (notification.event === 'share') {
    return 'shared a search with you';
  } else if (notification.event === 'delete') {
    return `deleted the search shared with you ${notification.details?.search_name || ''}`;
  }

  return null;
};

const getPersonaNotificationTitle = (notification: Notification) => {
  if (notification.event === 'share') {
    return 'shared a persona with you';
  } else if (notification.event === 'delete') {
    return `deleted the persona shared with you ${notification.details?.search_name || ''}`;
  }

  return null;
};

const getExportNotificationTitle = (notification: Notification) => {
  if (notification.event === 'download') {
    const count = notification.details?.number_of_records || '';
    const recordType = notification.details?.record_type || '';
    return `exported ${count.toLocaleString()} ${getRecordsLabel(recordType, count)}`;
  }

  return null;
};

const getBounceNotificationTitle = (notification: Notification) => {
  if (notification.event === 'resolve') {
    return 'has resolved your bounce file.';
  }

  return null;
};

const getAdminNotificationTitle = (notification: Notification) => {
  if (notification.event === 'credits_adjustment') {
    const formattedAmount = Math.abs(notification.details?.amount).toLocaleString();

    return `${formattedAmount} credits have been ${
      notification.details?.amount < 0 ? 'removed from' : 'added to'
    } your balance`;
  } else if (notification.event === 'subscription_adjustment') {
    return 'your subscription has been updated';
  }

  return null;
};

const getRevealNotificationTitle = (notification: Notification) => {
  const formattedRequested = Math.abs(notification.details?.requested).toLocaleString();

  if (notification.details?.status === 'completed') {
    const formattedRevealed = Math.abs(notification.details?.revealed).toLocaleString();

    return `Your request to reveal ${formattedRequested} contacts was completed successfully. Total credits used: ${formattedRevealed}`;
  }

  return `There was an error in completing your request to reveal ${formattedRequested} contacts. Please try again.`;
};

const getWelcomeNotificationTitle = (notification: Notification) => {
  return notification.message;
};
const getBookmarkNotificationTitle = (notification: Notification) => {
  return `exported ${notification?.details?.file_name}`;
};
const getBookmarkTitle = (notification: Notification) => {
  if (notification?.event === 'share') {
    return `shared a bookmark with you`;
  }
  if (notification.event === 'comment') {
    return `commented on a bookmark`;
  }
  return `performed an action on a bookmark`;
};

export const getTitle = (notification: Notification) => {
  if (notification.type === 'credit') {
    return getCreditNotificationTitle(notification);
  } else if (notification.type === 'list') {
    return getListNotificationTitle(notification);
  } else if (notification.type === 'saved_search') {
    return getSavedSearchNotificationTitle(notification);
  } else if (notification.type === 'persona') {
    return getPersonaNotificationTitle(notification);
  } else if (notification.type === 'export') {
    return getExportNotificationTitle(notification);
  } else if (notification.type === 'bounce') {
    return getBounceNotificationTitle(notification);
  } else if (notification.type === 'admin') {
    return getAdminNotificationTitle(notification);
  } else if (notification.type === 'reveal') {
    return getRevealNotificationTitle(notification);
  } else if (notification.type === 'welcome') {
    return getWelcomeNotificationTitle(notification);
  } else if (notification.type === 'bookmark_export') {
    return getBookmarkNotificationTitle(notification);
  } else if (notification.type === 'bookmark') {
    return getBookmarkTitle(notification);
  }

  return null;
};

export const getUserName = (notification: Notification, currentUser?: AccountBase) => {
  let name = truncate(notification.issued_by?.name, { length: 36 }) || 'System notification';

  if (notification.issued_by?.username === currentUser?.username) {
    name = 'You';
  }

  if (
    notification?.organization_notification &&
    notification?.event === 'resolve' &&
    notification?.type === 'bounce'
  ) {
    name = 'The Klarity Team';
  }

  return name;
};

export const getMessage = (notification: Notification) => {
  if (
    notification.type === 'welcome' ||
    notification.type === 'reveal' ||
    (notification.type === 'credit' &&
      (notification.event === 'decrease' || notification.event === 'expiry'))
  ) {
    // ignore notification message
    return undefined;
  }

  return notification.message;
};
