import React from 'react';
import { OptionWithNestedOptions } from '../types';
import { Checkbox } from '@demandscience/ui';
import { IndustryByCategory, SubIndustry } from 'types';

interface SelectedOptions {
  [industryId: string]: {
    [optionId: string]: boolean;
  };
}

interface IndustryWithTotal extends IndustryByCategory {
  total_companies?: number;
}

interface SubIndustryWithTotal extends SubIndustry {
  total_companies?: number;
}

interface IndustryListProps {
  data: (OptionWithNestedOptions<IndustryWithTotal, SubIndustryWithTotal> & {
    options?: (OptionWithNestedOptions<SubIndustryWithTotal, any> & {
      total_companies?: number;
    })[];
    total_companies?: number;
  })[];
  selectedOptions: SelectedOptions;
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>;
}

const numberFormatter = new Intl.NumberFormat('en-US');

const IndustryList: React.FC<IndustryListProps> = ({
  data,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleCheckboxChange = (industryId: string, optionId: string) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [industryId]: {
        ...prev[industryId],
        [optionId]: !prev[industryId]?.[optionId],
      },
    }));
  };

  const handleIndustryCheckboxChange = (industryId: string) => {
    const industryOptions = data.find((industry) => industry.id === industryId)?.options;
    if (!industryOptions) return;

    const isAllSelected = industryOptions.every(
      (option) => selectedOptions[industryId]?.[option.id],
    );

    const newOptionsState = industryOptions.reduce((acc, option) => {
      acc[option.id] = !isAllSelected;
      return acc;
    }, {} as { [optionId: string]: boolean });

    setSelectedOptions((prev) => ({
      ...prev,
      [industryId]: newOptionsState,
    }));
  };

  const isIndustryAllSelected = (industryId: string) => {
    const industryOptions = data.find((industry) => industry.id === industryId)?.options;
    if (!industryOptions) return false;

    return industryOptions.every((option) => selectedOptions[industryId]?.[option.id]);
  };

  if (!data || data.length === 0) {
    return <div>No industries available.</div>;
  }

  const industriesWithHeights = data.map((industry) => {
    const height = (industry.options?.length || 0) + 1; // +1 for the industry heading
    return { industry, height };
  });

  industriesWithHeights.sort((a, b) => b.height - a.height);

  const columns: (OptionWithNestedOptions<IndustryWithTotal, SubIndustryWithTotal> & {
    options?: (OptionWithNestedOptions<SubIndustryWithTotal, any> & {
      total_companies?: number;
    })[];
    total_companies?: number;
  })[][] = [[], [], []];

  const columnHeights = [0, 0, 0];

  industriesWithHeights.forEach(({ industry, height }) => {
    const minHeightColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));
    columns[minHeightColumnIndex].push(industry);
    columnHeights[minHeightColumnIndex] += height;
  });

  return (
    <div className="flex flex-wrap font-inter">
      {columns.map((columnData, columnIndex) => (
        <div key={columnIndex} className="w-full lg:w-1/3 pr-4">
          {columnData.map((industry) => (
            <div key={industry.id} className="mb-1">
              {/* Industry Heading with Checkbox */}
              <div className="flex items-center">
                <Checkbox
                  id={`industry-${industry.id}`}
                  size="sm"
                  checked={isIndustryAllSelected(industry.id)}
                  onChange={() => handleIndustryCheckboxChange(industry.id)}
                />
                <label
                  htmlFor={`industry-${industry.id}`}
                  className="font-medium text-xs leading-4 text-gray-500 flex items-center w-full ml-2"
                >
                  <span>{industry.label}</span>
                  {typeof industry.total_companies === 'number' && (
                    <span className="ml-auto text-xs text-gray-500">
                      {`[${numberFormatter.format(industry.total_companies)}]`}
                    </span>
                  )}
                </label>
              </div>
              {/* Sub-Category Options */}
              <div className="ml-6">
                {industry.options?.map((option) => (
                  <div key={option.id} className="flex items-center mb-1.5 h-[18px]">
                    <Checkbox
                      size="sm"
                      id={`${industry.id}-${option.id}`}
                      checked={selectedOptions[industry.id]?.[option.id] || false}
                      onChange={() => handleCheckboxChange(industry.id, option.id)}
                    />
                    <label
                      htmlFor={`${industry.id}-${option.id}`}
                      className="font-medium text-xs leading-4 text-gray-500 flex items-center w-full ml-2"
                    >
                      <span>{option.label}</span>
                      {typeof option.total_companies === 'number' && (
                        <span className="ml-auto text-[12px] text-gray-500">
                          {`[${numberFormatter.format(option.total_companies)}]`}
                        </span>
                      )}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default IndustryList;
