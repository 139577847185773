import { Filters, Month } from './filters';

type SocalProfile = {
  profile_handle: string;
  profile_pic_url?: string;
};

type Address = {
  address_line_1: string;
  address_line_2: string;
} & Location;

type Phone = {
  area_code?: string;
  country_code?: string;
  extension?: string;
  is_primary?: boolean;
  number: string;
  phone_type?: string;
  verified_at?: string;
};

type Email = {
  email_address: string;
  email_type: 'business' | 'personal';
};

type Job = {
  details: Array<{
    area: string;
    function: string;
    level: string;
  }>;
  display_title: string;
  is_primary?: boolean;
};

export type Location = {
  city?: string;
  country?: string;
  country_code_iso2?: string;
  county?: string;
  designation: 'city' | 'country' | 'county' | 'state' | 'global_region';
  global_region?: string;
  state?: string;
};

export type Intent = {
  category?: string;
  parent_category?: string;
  topic: string;
};

export type TechInstall = {
  category?: string;
  month?: Month;
  name: string;
  sub_category?: string;
};

export type EmployeesSizeRange = {
  range_max: number;
  range_min: number;
  size_range: string;
};

export type Industry = IndustryByCategory | IndustryBySIC | IndustryByNAICS;

export type IndustryByCategory = {
  category: string;
  sub_category?: { sub_category: string; total_companies?: number }[];
  total_companies?: number;
};

// there's inconsistence between structure used for the industry filter above
// and the data returned as industry value on a company search
export type IndustryByCategoryResponse = Omit<IndustryByCategory, 'sub_category'> & {
  sub_categories?: IndustryByCategory['sub_category'];
};

export type IndustryBySIC = {
  sic: number;
};

export type IndustryByNAICS = {
  naics: number;
};

export type SubIndustry = {
  sub_category: string;
  total_companies?: number;
};

export type JobFunction = {
  function: string;
};

export type JobLevel = {
  level: string;
};

export type RevenueRange = {
  range_max: number;
  range_min: number;
  rev_range: string;
};

export type Keyword = {
  keyword: string;
};

export type Company = {
  business_keywords?: Array<string>;
  company_name: string;
  dsid: string;
  employees_size_range: EmployeesSizeRange;
  industry: IndustryByCategoryResponse;
  intents?: Array<Intent & { topic_confidence_score: number }>;
  linkedin_profiles: Array<SocalProfile>;
  logo_url?: string;
  main_domains: Array<string>;
  offices: Array<Address>;
  phones: Array<Phone>;
  revealed: boolean;
  revenues: RevenueRange;
  tech_installs?: Array<TechInstall>;
  twitter_profiles: Array<SocalProfile>;
};

export type Contact = {
  avatar_url?: string;
  companies: Array<Company>;
  dsid: string;
  emails: Array<Email>;
  facebook_profiles: Array<SocalProfile>;
  first_name: string;
  jobs: Array<Job>;
  last_name: string;
  linkedin_profiles: Array<SocalProfile>;
  locations: Array<Address>;
  middle_name?: string;
  phones: Array<Phone>;
  revealed?: boolean;
  twitter_profiles: Array<SocalProfile>;
};

export type Model = Contact | Company;

export type ModelId = Pick<Model, 'dsid'>;

export enum ModelType {
  Company = 'company',
  Contact = 'contact',
}

export interface SnackbarContextInterface {
  showMessage: (
    message: React.SyntheticEvent<HTMLButtonElement, Event> | string,
    status: string,
  ) => void;
}

export enum Visibility {
  Private = 'private',
  Public = 'public',
  Restricted = 'restricted',
}

export enum VisibilityBookmarks {
  'All bookmarks' = 'all',
  'Owned by me' = 'own',
  'Shared with me' = 'shared',
}
export const visibilityOptions = [
  {
    value: Visibility.Private,
    label: 'Private',
  },
  {
    value: Visibility.Public,
    label: 'Whole team',
  },
  {
    value: Visibility.Restricted,
    label: 'Specific people',
  },
];

export enum Show {
  All = 'all',
  Editable = 'editable',
  GlobalExclusion = 'global_exclusion',
  Own = 'own',
  Shared = 'shared',
  Usable = 'usable',
}

export type NotificationMessage = string;

export enum ResultsView {
  Companies = 'companies',
  Contacts = 'contacts',
  DataProfile = 'data profile',
}

export enum Role {
  Manager = 'manager',
  User = 'user',
}

export type ActiveAccounts = {
  available: number;
  quota: number;
  used: number;
};

export type BulkCredits = {
  allocation: allocation;
  available: number;
  current_period_used: number;
  quota: number;
  used: number;
};

export type Subscription = {
  credits_left: number;
  ended_at: string;
  plan: string;
  started_at: string;
};

export type OrganizationState = 'active' | 'inactive' | 'blocked';

export type Organization = {
  active_accounts?: ActiveAccounts;
  bulk_credits_usage?: BulkCredits;
  created_at: string;
  disabled_features?: Array<KlarityFeature>;
  id: string;
  lab_disabled?: boolean;
  list_write_disabled: boolean;
  name: string;
  selfservice_subscription_disabled?: boolean;
  state: OrganizationState;
  subscriptions?: Array<Subscription>;
};

export enum KlarityFeature {
  Companies = 'companies',
  CompareSearches = 'compare-searches',
  Contacts = 'contacts',
  ContactsRendered = 'contacts_rendered',
}

export enum MemberState {
  Active = 'active',
  Invited = 'invited',
  Suspended = 'suspended',
}

export type allocation = 'unlimited' | 'monthly' | 'fixed';

export type Member = Account & {
  bulk_credits: BulkCredits;
  invited_at: Date;
  number_of_exports: number;
  number_of_lists: number;
  number_of_saved_searches: number;
  number_of_searches: number;
  number_of_shares: number;
  number_of_signins: number;
  state: MemberState;
};

export type AccountBase = {
  created_at: string;
  email: string;
  has_avatar?: boolean;
  name: string;
  username: string;
};

export type Account = AccountBase & {
  bulk_credits: BulkCredits;
  impersonated?: true;
  last_signin_at?: string;
  number_of_signins: number;
  organization: Organization;
  roles: Role[];
};

export type SavedSearch = {
  created_at: string;
  current_view: ResultsView;
  description?: string;
  filters: Filters;
  id: string;
  is_a_persona?: boolean;
  name: string;
  owner: AccountBase;
  shared_with: AccountBase[];
  visibility: Visibility;
};

export const isSavedSearch = (search: SavedSearch | RecentSearch): search is SavedSearch =>
  search.hasOwnProperty('name');

export type InviteStatus = 'success' | 'invalid' | 'already_a_member' | 'taken' | 'technical_error';

export type InviteResult = {
  email: string;
  status: InviteStatus;
};

export type RecentSearch = {
  created_at: string;
  current_view: ResultsView;
  filters: Filters;
  id: string;
};

export type BadgeSharedProps = {
  title: string;
};

export enum FormMode {
  Create = 'create',
  Edit = 'edit',
}

export type NotificationMessagePayload = {
  notification_message?: NotificationMessage;
};

export type MessagePayload = {
  message?: string;
};

export type SortBy = {
  by: string;
  descending: boolean;
};

export type Pagination = {
  count: number;
  index: number;
};

export type PaginationResponse = {
  count: number;
  returned: number;
};

export type SearchParams<T> = {
  filter?: T;
  refresh?: boolean;
  sort: SortBy;
} & Pagination;

export type ExportData = {
  batchSelection?: true;
  dsid?: string[];
  filters?: Filters;
};

export enum ViewLayout {
  List = 'list',
  Table = 'table',
}

export enum AutoUpdate {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Weekly = 'bi-weekly',
}

export const AutoUpdateOptions = [
  {
    value: AutoUpdate.Weekly,
    label: 'Bi-Weekly',
  },
  {
    value: AutoUpdate.Monthly,
    label: 'Monthly',
  },
  {
    value: AutoUpdate.Quarterly,
    label: 'Quarterly',
  },
];
