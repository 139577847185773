import { ModelType, Visibility } from 'types';
import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import CreateListOverlay from './CreateListOverlay';
import { ElementType, ReactElement, ReactNode } from 'react';
import useOrganization from 'components/Organization/useOrganization';

interface CreateListControlProps {
  as?: ElementType;
  borderless: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  leftIcon: ReactElement;
  onSuccess?: (id: string, visibility: Visibility) => void;
  size?: string;
  theme?: string;
  type?: ModelType;
}

const CreateListControl = ({
  as: Component = Button,
  type,
  onSuccess,
  disabled = false,
  ...props
}: CreateListControlProps) => {
  const { open, openModal, closeModal } = useModal();
  const { data: organization } = useOrganization();
  const vaultLoading = organization?.list_write_disabled;

  return (
    <>
      <Component
        style={{
          cursor: vaultLoading ? 'not-allowed' : 'pointer',
        }}
        disabled={vaultLoading}
        type="button"
        onClick={openModal}
        {...props}
      />
      <CreateListOverlay open={open} onClose={closeModal} onSuccess={onSuccess} type={type} />
    </>
  );
};

export default CreateListControl;
