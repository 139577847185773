import React, { useState, useCallback, useRef } from 'react';
import { DropdownMenu } from '@demandscience/ui';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import './PageDropdown.css';
import { Origin } from '@demandscience/ui/dist/types';

type PageDropdownProps = {
  currentPage: number;
  onPageChange: (page: number) => void;
  origin: Origin;
  totalPages: number;
};

const PageDropdown: React.FC<PageDropdownProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  origin,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [pageOptions, setPageOptions] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const itemsPerBatch = 100;
  const [loadedBatches, setLoadedBatches] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const loadMorePages = useCallback(() => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      const nextBatch = Array.from(
        { length: itemsPerBatch },
        (_, i) => loadedBatches * itemsPerBatch + i + 1,
      ).filter((pageNumber) => pageNumber <= totalPages);
      setPageOptions((prevOptions) => [...prevOptions, ...nextBatch]);
      setLoadedBatches((prev) => prev + 1);
      setLoading(false);
    }, 0);
  }, [loading, loadedBatches, totalPages]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (pageOptions.length === 0) {
      loadMorePages();
    }
  }, [pageOptions.length, loadMorePages]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setPageOptions([]);
    setLoadedBatches(0);
  }, []);

  const handlePageSelect = useCallback(
    (pageNumber: number) => {
      onPageChange(pageNumber);
      handleClose();
    },
    [onPageChange, handleClose],
  );

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (container) {
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
        if (pageOptions.length < totalPages) {
          loadMorePages();
        }
      }
    }
  }, [loadMorePages, pageOptions.length, totalPages]);

  return (
    <div className="flex items-center gap-2">
      <span
        className="w-[34px] h-[20px] font-['Inter'] text-[14px] font-normal leading-[20px] text-center text-[#334155]"
        style={{ opacity: 1 }}
      >
        Page
      </span>

      <DropdownMenu
        open={open}
        onCloseAction={handleClose}
        buttonType="custom"
        customButton={
          <button
            className="w-[43px] h-[26px] flex items-center border border-[#CBD5E1] rounded-[5px]"
            onClick={handleOpen}
          >
            <span className="w-[17px] h-[20px] text-[#334155] font-['Inter'] text-[14px] font-normal leading-[20px] text-center">
              {currentPage}
            </span>
            <div className="w-[25px] h-[18px] flex items-center justify-center">
              <ChevronDownIcon className="text-[#334155] w-4 h-4" />
            </div>
          </button>
        }
        origin={origin}
        dropdownClassName=" w-[43px]"
      >
        <div
          className="overflow-y-auto overflow-x-hidden scrollbar"
          style={{ maxHeight: '260px' }}
          onScroll={handleScroll}
          ref={scrollContainerRef}
        >
          {pageOptions.map((pageNumber) => (
            <DropdownMenu.Item
              key={pageNumber}
              onClick={() => handlePageSelect(pageNumber)}
              className={`flex justify-center items-center ${
                currentPage === pageNumber ? 'bg-gray-200' : ''
              }`}
            >
              <span className="text-[#334155] text-center">{pageNumber}</span>
            </DropdownMenu.Item>
          ))}
        </div>
      </DropdownMenu>
    </div>
  );
};

export default PageDropdown;
