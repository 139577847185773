import { ModelType } from 'types';
import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import { ElementType, ReactElement, ReactNode } from 'react';
import UploadListOverlay from './UploadListOverlay';
import useOrganization from 'components/Organization/useOrganization';

interface UploadListControlProps {
  as?: ElementType;
  borderless?: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  leftIcon: ReactElement;
  size?: string;
  theme?: string;
  type?: ModelType;
}

const UploadListControl = ({
  as: Component = Button,
  type,
  disabled = false,
  ...props
}: UploadListControlProps) => {
  const { open, openModal, closeModal } = useModal();
  const { data: organization } = useOrganization();
  const vaultLoading = organization?.list_write_disabled;

  return (
    <>
      <Component disabled={vaultLoading} onClick={openModal} {...props} />
      <UploadListOverlay open={open} onClose={closeModal} type={type} />
    </>
  );
};

export default UploadListControl;
