import { Button, Modal, RadioList } from '@demandscience/ui';
import { downloadTeam } from 'api/organization';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { useState } from 'react';

export const TeamDownload = ({ open, onClose }: any) => {
  const [selectedFormat, setSelectedFormat] = useState<string | null>('csv');
  const { showMessage } = useSnackbar();

  const handleDownload = async () => {
    if (selectedFormat) {
      onClose();
      try {
        await downloadTeam();
      } catch (error) {
        if (error) {
          const errorMessage = 'Unexpected error, please try again later';

          showMessage(errorMessage, 'error');
        }
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="create-list-dialog-title"
        className="max-w-md sm:max-w-md overflow-visible"
        rootClassName="z-30"
        open={open}
        onClose={onClose}
      >
        <Modal.Title id="create-list-dialog-title" className="flex mb-2">
          Download team file
        </Modal.Title>

        <>
          <div className="mb-4">Choose a format</div>
          <div className="mb-4 flex items-center space-x-2">
            <div className="max-w-xs">
              <RadioList
                onChange={(value) => setSelectedFormat(value)}
                options={[
                  {
                    label: 'CSV',
                    value: 'csv',
                  },
                ]}
                radioIconClassNames="mr-2"
                size="lg"
                theme="primary"
                value="csv"
              />
            </div>
          </div>{' '}
          <div className="flex justify-end space-x-4">
            <Button type="button" borderless onClick={onClose}>
              Cancel
            </Button>
            <Button theme="primary" onClick={handleDownload}>
              Download
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};
