import { isEmpty } from 'lodash';
import { CreditsActivityFilter, CreditsActivityResponse } from 'types';
import { DataGrid, Link } from '@demandscience/ui';
import { RowItem } from '@demandscience/ui/dist/types';
import { columns } from './CreditsActivityTableColumns';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import CreditsActivitySkeleton from './CreditsActivitySkeleton';
import useCreditsActivitySearch from './useCreditsActivitySearch';
import useDataGridSearch from 'components/DataGrid/useDataGridSearch';
import Filters from './Filters';
import Paragraph from 'components/Typography/Paragraph';
import { useCallback } from 'react';

const defaultParams = {
  index: 0,
  count: 25,
  sort: { by: 'created_at', descending: true },
};

const SearchCreditsActivity = () => {
  const { params, handleFilterChange, handlePageChange, handleSortChange, query } =
    useDataGridSearch<CreditsActivityFilter, CreditsActivityResponse>(
      useCreditsActivitySearch,
      defaultParams,
    );
  const { data, isLoading, isFetching, isError } = query;

  let children;

  const handleSeeAll = useCallback(() => {
    handleFilterChange({});
  }, [handleFilterChange]);

  if (isError) {
    children = <div className="text-sm text-error-500">Unexpected error loading credits logs</div>;
  } else if (isLoading || (isEmpty(data.credits) && isFetching)) {
    children = <CreditsActivitySkeleton count={5} />;
  } else if (isEmpty(data.credits) && !isFetching) {
    if (isEmpty(params.filter)) {
      children = (
        <EmptyResults
          message="No credits used so far."
          illustration={<Illustration.Empty className="w-36 h-36 mb-6" />}
        />
      );
    } else {
      children = (
        <EmptyResults
          message="No credits log matching your criteria"
          illustration={<Illustration.Absurd2 className="w-36 h-36 mb-6" />}
          className="mt-36"
        >
          <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
            <Link onClick={handleSeeAll}>See all credits logs</Link>
          </Paragraph>
        </EmptyResults>
      );
    }
  } else {
    const { credits: rows, count } = data || {};

    children = (
      <div className="flex-1 isolate">
        <DataGrid
          columns={columns}
          rows={rows as RowItem[]}
          loading={isFetching}
          uniqueIdentifier="username"
          disableInternalComputation
          sortingMode="server"
          onSortModelChange={handleSortChange}
          containerClassName="overflow-visible"
          // pagination
          hideFooterRowCount
          page={params.index + 1}
          rowsPerPage={params.count}
          count={count}
          onPageChange={handlePageChange}
          paginationVariant="combined"
        />

        {isEmpty(rows) && !isFetching && (
          <p className="text-gray-500 py-4 text-center">No record found</p>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="mb-4 md:mt-6 md:mb-16 flex-shrink-0 flex flex-col md:items-center md:flex-row space-y-2 md:space-y-0 md:space-x-4">
        <Filters params={params} value={params.filter} onChange={handleFilterChange} />
      </div>
      {children}
    </>
  );
};

export default SearchCreditsActivity;
