import { includes, values } from 'lodash';
import {
  ResultsView,
  BulkCredits,
  AccountBase,
  ContactsSort,
  CompaniesSort,
  Pagination,
  ExportType,
  PaginationResponse,
  ViewLayout,
} from 'types';

export type UpdateNamePayload = {
  name: string;
};

export type UpdateEmailPayload = {
  confirm?: string;
  new?: string;
};

export type UpdatePasswordPayload = {
  previous_password: string;
  proposed_password: string;
};

export type Credit = {
  bulk_credits: BulkCredits;
  credits_rollover: string;
};

export type FetchNotificationsParams = {
  include_read: boolean;
} & Pagination;

export type Notification = {
  created_at: string;
  details?: Record<string, any>;
  event?:
    | 'request'
    | 'grant'
    | 'reject'
    | 'share'
    | 'delete'
    | 'download'
    | 'resolve'
    | 'async'
    | 'decrease'
    | 'expiry'
    | 'credits_adjustment'
    | 'subscription_adjustment'
    | 'comment';
  id: string;
  issued_by?: Pick<AccountBase, 'username' | 'name' | 'email' | 'has_avatar'>;
  message: string;
  organization_notification?: boolean;
  read_at?: string;
  type?:
    | 'credit'
    | 'list'
    | 'saved_search'
    | 'persona'
    | 'export'
    | 'bounce'
    | 'admin'
    | 'reveal'
    | 'welcome'
    | 'bookmark_export'
    | 'bookmark';
};

export type FetchNotificationsResponse = {
  notifications: Notification[];
} & PaginationResponse;

export enum CompanyTableColumn {
  CompanyAddress = 'company_address',
  CompanyLocation = 'company_location',
  CompanyName = 'company_name',
  ContactDetails = 'contact_details',
  Headcount = 'headcount',
  Industry = 'industry',
  InstallTopic = 'install_topic',
  IntentTopic = 'intent_topic',
  Revenue = 'revenue',

  Specialities = 'specialities',
  Subindustry = 'subindustry',
}

export const allCompanyTableColumns = Object.values(CompanyTableColumn);

export const defaultCompanyTableColums = [
  CompanyTableColumn.CompanyName,
  CompanyTableColumn.Industry,
  CompanyTableColumn.ContactDetails,
  CompanyTableColumn.CompanyLocation,
  CompanyTableColumn.Headcount,
  CompanyTableColumn.Revenue,
  CompanyTableColumn.IntentTopic,
  CompanyTableColumn.InstallTopic,
  CompanyTableColumn.Specialities,
];

export enum ContactTableColumn {
  Company = 'company',
  ContactDetails = 'contact_details',
  ContactLocation = 'contact_location',
  ContactName = 'contact_name',
  JobFunction = 'job_function',
  JobLevel = 'job_level',
  JobTitle = 'job_title',
}

export const isContactTableColum = (value: string): value is ContactTableColumn =>
  includes(values(ContactTableColumn), value);

export const allContactTableColumns = [
  ...values(ContactTableColumn),
  CompanyTableColumn.CompanyAddress,
  CompanyTableColumn.CompanyLocation,
  CompanyTableColumn.Headcount,
  CompanyTableColumn.Industry,
  CompanyTableColumn.Revenue,
  CompanyTableColumn.Subindustry,
  CompanyTableColumn.IntentTopic,
  CompanyTableColumn.InstallTopic,
  CompanyTableColumn.Specialities,
];

export const defaultContactTableColumns = [
  ContactTableColumn.ContactName,
  ContactTableColumn.JobTitle,
  ContactTableColumn.ContactDetails,
  ContactTableColumn.Company,
  CompanyTableColumn.Industry,
  CompanyTableColumn.Headcount,
  CompanyTableColumn.Revenue,
  CompanyTableColumn.IntentTopic,
  CompanyTableColumn.InstallTopic,
  CompanyTableColumn.Specialities,
];

export const tableColumns: Record<ContactTableColumn | CompanyTableColumn, string> = {
  [ContactTableColumn.ContactName]: 'Contact name',
  [ContactTableColumn.JobTitle]: 'Job title',
  [ContactTableColumn.ContactDetails]: 'Contact details',
  [ContactTableColumn.Company]: 'Company',
  [ContactTableColumn.ContactLocation]: 'Contact location',
  [ContactTableColumn.JobFunction]: 'Department',
  [ContactTableColumn.JobLevel]: 'Job level',
  [CompanyTableColumn.CompanyName]: 'Company name',
  [CompanyTableColumn.CompanyLocation]: 'Company location',
  [CompanyTableColumn.CompanyAddress]: 'Company address',
  [CompanyTableColumn.Industry]: 'Industry',
  [CompanyTableColumn.Headcount]: 'Headcount',
  [CompanyTableColumn.Revenue]: 'Revenue',
  [CompanyTableColumn.Subindustry]: 'Sector',
  [CompanyTableColumn.IntentTopic]: 'Buyer intent',
  [CompanyTableColumn.InstallTopic]: 'Technology install',
  [CompanyTableColumn.Specialities]: 'Specialities',
};

export type SearchCustomization = {
  exports: {
    pinned?: ExportType[];
  };
  filters: {
    dense: boolean;
    position: 'left' | 'right';
    width: number;
  };
  layout: {
    fullWidth: boolean;
    view: ViewLayout;
  };
  results: {
    companiesSortField: CompaniesSort;
    companiesTableColums: CompanyTableColumn[];
    contactsSortField: ContactsSort;
    contactsTableColums: (ContactTableColumn | CompanyTableColumn)[];
    tab: ResultsView;
  };
};

export type UpdateSearchCustommizationsPayload = Partial<SearchCustomization>;
