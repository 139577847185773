import { useCallback, useContext } from 'react';
import without from 'lodash/without';
import includes from 'lodash/includes';
import { ExportType, KlarityFeature, ModelType } from 'types';

import useSnackbar from 'components/Snackbar/useSnackbar';
import useSelection from 'components/DataGrid/Selection/useSelection';
import useSearchCustomization from 'components/Account/useSearchCustomization';
import useMutateSearchCustomization from 'components/Account/useMutateSearchCustomization';

import ExportDropdown from './ExportDropdown';
import useExport from './useExport';

import { IconButton, Tooltip } from '@demandscience/ui';
// Icons import
import EyeIcon from '@demandscience/ui/icons/eye';
import CsvIcon from '@demandscience/ui/icons/CSV';
import AddToListIcon from '@demandscience/ui/icons/add-to-list';
import SalesforceIcon from '@demandscience/ui/icons/salesforce';
import HubspotIcon from '@demandscience/ui/icons/hubspot';
import MarketoIcon from '@demandscience/ui/icons/marketo';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import MoreHorizontalIcon from '@demandscience/ui/icons/more-horizontal';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';
import SearchContext from 'components/Search/SearchContext';

const defaultPinnedExports = ['csv', 'toList', 'reveal'] as ExportType[];

interface ExportOptionsProps {
  children?: React.ReactNode;
  vaultLoading?: boolean;
}

const ExportOptions = ({ children, vaultLoading }: ExportOptionsProps) => {
  const { featureStatus, requestFeatures } = useFeatureFlag(KlarityFeature.Contacts);

  const { exportData } = useSelection();
  const { exportTo, modelType, hasIntegration } = useExport();
  const searchStore = useContext(SearchContext);

  const { showMessage } = useSnackbar();

  const { data, isLoading } = useSearchCustomization();
  const { update } = useMutateSearchCustomization();

  const { exports: { pinned = defaultPinnedExports } = {} } = data || {};

  const handleExportClick = useCallback(
    (type: ExportType) => () => {
      if (modelType === ModelType.Contact && featureStatus) {
        requestFeatures();
        return;
      }
      let modifiedExportData = JSON.parse(JSON.stringify(exportData));

      if (!modifiedExportData.filters) {
        modifiedExportData.filters = {};
      }

      if (!Array.isArray(modifiedExportData.filters.exclusion_list)) {
        modifiedExportData.filters.exclusion_list = [];
      }
      if (searchStore && searchStore[modelType]?.excludeList) {
        modifiedExportData.filters.exclusion_list.push(searchStore[modelType].excludeList);
      }

      exportTo(type, modifiedExportData);
    },
    [exportData, exportTo, featureStatus, modelType, requestFeatures, searchStore],
  );

  const handlePinClick = useCallback(
    async (type: ExportType) => {
      try {
        const value = includes(pinned, type) ? without(pinned, type) : [...pinned, type];

        await update.mutateAsync({
          ...data,
          exports: { ...data?.exports, pinned: value },
        });
      } catch {
        showMessage('Unable to update customization settings', 'error');
      }
    },
    [data, pinned, showMessage, update],
  );

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-row items-center -my-1">
      {hasIntegration('salesforce') && includes(pinned, 'salesforce') && (
        <Tooltip title="Export to Salesforce" position="bottom" showArrow>
          <IconButton
            disabled={vaultLoading}
            className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
            size="lg"
            theme="none"
            Icon={SalesforceIcon}
            onClick={handleExportClick('salesforce')}
          />
        </Tooltip>
      )}
      {modelType === ModelType.Contact &&
        hasIntegration('marketo') &&
        includes(pinned, 'marketo') && (
          <Tooltip title="Export to Marketo" position="bottom" showArrow>
            <IconButton
              disabled={vaultLoading}
              className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
              size="lg"
              theme="none"
              Icon={MarketoIcon}
              onClick={handleExportClick('marketo')}
            />
          </Tooltip>
        )}
      {hasIntegration('hubspot') && includes(pinned, 'hubspot') && (
        <Tooltip title="Export to Hubspot" position="bottom" showArrow>
          <IconButton
            disabled={vaultLoading}
            className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
            size="lg"
            theme="none"
            Icon={HubspotIcon}
            onClick={handleExportClick('hubspot')}
          />
        </Tooltip>
      )}
      {includes(pinned, 'csv') && (
        <Tooltip title="Export to CSV" position="bottom" showArrow>
          <IconButton
            disabled={vaultLoading}
            className="text-primary-500 hover:text-primary-600"
            size="lg"
            theme="primary"
            Icon={CsvIcon}
            onClick={handleExportClick('csv')}
          />
        </Tooltip>
      )}
      {includes(pinned, 'toList') && (
        <Tooltip title="Add to list" position="bottom" showArrow>
          <IconButton
            disabled={vaultLoading}
            size="lg"
            theme="primary"
            Icon={AddToListIcon}
            onClick={handleExportClick('toList')}
          />
        </Tooltip>
      )}
      {modelType === ModelType.Contact && includes(pinned, 'reveal') && (
        <Tooltip title="Show contact info" position="bottom" showArrow>
          <IconButton
            disabled={vaultLoading}
            size="lg"
            theme="primary"
            Icon={EyeIcon}
            onClick={handleExportClick('reveal')}
          />
        </Tooltip>
      )}
      {includes(pinned, 'linkedin') && (
        <Tooltip title="Browse on LinkedIn" position="bottom" showArrow>
          <IconButton
            className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
            size="lg"
            theme="none"
            Icon={LinkedinIcon}
            onClick={handleExportClick('linkedin')}
          />
        </Tooltip>
      )}
      <ExportDropdown
        buttonType="custom"
        customButton={
          <Tooltip title="More actions" position="bottom" showArrow>
            <IconButton size="lg" theme="primary" Icon={MoreHorizontalIcon} />
          </Tooltip>
        }
        onClick={handleExportClick}
        pinned={pinned}
        onPinClick={handlePinClick}
        hideReveal={modelType !== ModelType.Contact}
      >
        {children}
      </ExportDropdown>
    </div>
  );
};

export default ExportOptions;
