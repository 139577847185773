import { ListOrigin, ListRecord } from 'types';
import useModal from 'hooks/useModal';
import useAuth from 'components/Auth/useAuth';
import useModelPermissions from 'hooks/useModelPermissions';
import ListRecordEditActionModal from './ListRecordEditActionModal';
import ListRecordDeleteActionModal from './ListRecordDeleteActionModal';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import { DropdownMenu, DropdownMenuProps, IconButton, Tooltip } from '@demandscience/ui';
import classNames from 'classnames';
import useOrganization from 'components/Organization/useOrganization';

interface ListRecordActionsProps extends DropdownMenuProps {
  list: ListRecord;
}

const ListRecordActions = ({ list, ...props }: ListRecordActionsProps) => {
  const { open: editModalOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { data: organization } = useOrganization();

  const {
    open: deleteConfirmationModalOpen,
    openModal: openDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
  } = useModal();

  const { user } = useAuth();
  const { owner, visibility } = list;
  const { isEditable } = useModelPermissions(user, owner, visibility);
  const invisible =
    !isEditable ||
    !(list.records_origin === ListOrigin.Search || list.records_origin === ListOrigin.Upload);

  return (
    <Tooltip
      title="Data loading is in progress, 
      this option will be available shortly"
      position="left"
      interactive
      showArrow
      tooltipClassName="max-w-xs whitespace-normal break-words p-2"
      disabled={!organization?.list_write_disabled}
    >
      <DropdownMenu
        className={classNames({ invisible })}
        options={[
          { label: 'Edit list settings', action: () => openEditModal() },
          { label: 'Delete list', action: () => openDeleteConfirmationModal() },
        ]}
        buttonType="custom"
        customButton={
          <IconButton
            className="hover:bg-gray-100"
            size="sm"
            theme="default"
            Icon={MoreVerticalIcon}
          />
        }
        origin="bottom-right"
        {...props}
        disabled={organization?.list_write_disabled}
      />
      <ListRecordEditActionModal open={editModalOpen} onClose={closeEditModal} {...list} />
      <ListRecordDeleteActionModal
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        {...list}
      />
    </Tooltip>
  );
};

export default ListRecordActions;
