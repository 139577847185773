import { CompanyFilterName, ExcludableFilter, FilterName, badgeDisplayCap } from 'types/filters';
import { FilterProps } from './types';
import Icon from 'components/Filter/FilterIcon';
import Label from 'components/Filter/FilterLabel';
import Badges from 'components/Filter/Inline/Badges';
import ListItem from 'components/Filter/ListItem';
import IndustriesModal from './IndustryFilter/IndustryModul';
import useModal from 'hooks/useModal';
import useFilter from './useFilter';
import { isEmpty, map, reduce, reject } from 'lodash';
import { useCallback } from 'react';
import { IndustryByCategory, SubIndustry } from 'types';

type CompanyIndustryFilterParam =
  | (IndustryByCategory &
      ExcludableFilter & {
        sub_category?: Array<SubIndustry & ExcludableFilter>;
      })
  | undefined;

const getBadgeProps = (name: FilterName) => (value: any) => {
  let label = 'n/a';

  if (name === CompanyFilterName.CompanyIndustry) {
    if (value.sic) {
      label = `SIC: ${value.sic}`;
    } else if (value.naics) {
      label = `NAICS: ${value.naics}`;
    } else {
      label = value.sub_category || value.category;
    }
  }

  return {
    key: label,
    label,
    exclude: value.exclude,
  };
};

const getBadgeValues = (by: string, nestedBy: string, value?: any): any[] => {
  return reduce(
    value,
    // @ts-expect-error
    (res, filter) => {
      if (isEmpty(filter[nestedBy])) {
        return [...res, filter];
      }

      const nested = map(filter[nestedBy], (v) => ({
        [by]: filter[by],
        ...v,
      }));

      return [...res, ...nested];
    },
    [],
  );
};

const CompanyIndustryFilter = (props: FilterProps) => {
  const { open, openModal, closeModal } = useModal();
  const { value, onChange, onClear } = useFilter(CompanyFilterName.CompanyIndustry);
  const nestedBy = 'sub_category';
  const by = 'category';
  const handleDelete = useCallback(
    (deleted: CompanyIndustryFilterParam) => {
      let newValue;

      if (deleted?.[nestedBy]) {
        newValue = reduce(
          value,
          // @ts-expect-error
          (res, filter) => {
            if (by in filter && deleted[by] === filter[by]) {
              const nested = reject(filter[nestedBy], {
                [nestedBy]: deleted[nestedBy],
              });

              if (isEmpty(nested)) {
                return res;
              }

              return [
                ...res,
                {
                  ...filter,
                  [nestedBy]: nested,
                },
              ];
            }

            return [...res, filter];
          },
          [],
        );
      } else {
        newValue = reject(value, deleted);
      }

      onChange(newValue);
    },
    [onChange, value],
  );
  return (
    <>
      <ListItem
        className="cursor-pointer select-none"
        icon={<Icon name={CompanyFilterName.CompanyIndustry} />}
        label={<Label name={CompanyFilterName.CompanyIndustry} />}
        dense={props.dense}
        onClick={openModal}
        filled={!!value?.length}
        onClear={onClear}
      >
        <Badges
          className="ml-4 mb-4"
          badgeDisplayCap={badgeDisplayCap}
          onDelete={handleDelete}
          getBadgeProps={getBadgeProps(CompanyFilterName.CompanyIndustry)}
          value={getBadgeValues('category', 'sub_category', value)}
        />
      </ListItem>
      <IndustriesModal value={value} onChange={onChange} open={open} closeModal={closeModal} />
    </>
  );
};

export default CompanyIndustryFilter;
