import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { add, formatISO, isBefore, isEqual, format, subMonths } from 'date-fns';
import { DatepickerDates, DatepickerFormattedDates } from 'types';

interface DatepickerProps {
  limitStartDate?: Date;
  onChange?: (dates: DatepickerFormattedDates) => void;
  onConfirm?: () => void;
  onOpenDatepicker?: (open: boolean) => void;
}

const Datepicker = ({ onChange, onConfirm, limitStartDate }: DatepickerProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [openDatepicker, setOpenDatepicker] = useState(false); // Control opening/closing datepicker
  const [dateRangeText, setDateRangeText] = useState(''); // Text to display selected date range

  useEffect(() => {
    const formattedStartDate = startDate ? format(startDate, 'MM/dd/yyyy') : '';
    const formattedEndDate = endDate ? format(endDate, 'MM/dd/yyyy') : '';

    // Update the text field when dates are selected
    if (startDate && endDate) {
      setDateRangeText(`${formattedStartDate} - ${formattedEndDate}`);
    } else if (startDate) {
      setDateRangeText(`${formattedStartDate} -`);
    } else {
      setDateRangeText(''); // Reset if no date is selected
    }

    // Trigger the onChange callback if the dates change
    if (onChange) {
      const formattedStartDateISO = startDate ? formatISO(startDate) : null;
      const formattedEndDateISO = endDate ? formatISO(endDate) : null;
      onChange([formattedStartDateISO, formattedEndDateISO]);
    }
  }, [startDate, endDate, onChange]);

  const handleChange = (dates: DatepickerDates) => {
    let [start, end] = dates;

    if (start && end && isBefore(end, start)) {
      return;
    }

    if (start && end && isEqual(start, end)) {
      end = add(end, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      });
    }

    setStartDate(start);
    setEndDate(end);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(); // Automatically check the 'custom' checkbox
    }
    setOpenDatepicker(false); // Close datepicker on confirm
  };

  return (
    <div>
      <div className="flex flex-row gap-6 justify-between items-center">
        <input
          type="text"
          readOnly
          value={dateRangeText}
          onClick={() => setOpenDatepicker(true)}
          placeholder="Select a date range"
          className="w-full p-2 border rounded cursor-pointer"
        />
        {openDatepicker && (
          <div className="flex justify-end mt-2">
            <button
              onClick={() => setOpenDatepicker(false)}
              className="mr-2 bg-gray-200 text-gray-700 py-1 px-4 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-700"
            >
              Confirm
            </button>
          </div>
        )}
      </div>

      {openDatepicker && (
        <div className="absolute z-50 mt-2">
          <ReactDatePicker
            onChange={(dates) => handleChange(dates)}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            maxDate={new Date()}
            minDate={limitStartDate}
            selectsRange
            monthsShown={2}
            openToDate={subMonths(new Date(), 1)}
            inline
            showPopperArrow={false}
            calendarStartDay={1}
            className="min-w-[200px] cursor-pointer rounded bg-white py-2 pl-4 text-left duration-150 ease-in-out outline-none focus:ring-0 focus:outline-none border border-gray-300 hover:border-gray-700 text-sm"
          />
        </div>
      )}
    </div>
  );
};

export default Datepicker;
