import { ExportType, ModelType } from 'types';
import useExport from './useExport';
import includes from 'lodash/includes';
import TogglePinIconButton from './TogglePinIconButton';
import { DropdownMenu } from '@demandscience/ui';
import AddToListIcon from '@demandscience/ui/icons/add-to-list';
import CsvIcon from '@demandscience/ui/icons/CSV';
import EyeIcon from '@demandscience/ui/icons/eye';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import SalesforceIcon from '@demandscience/ui/icons/salesforce';
import HubspotIcon from '@demandscience/ui/icons/hubspot';
import MarketoIcon from '@demandscience/ui/icons/marketo';
import useOrganization from 'components/Organization/useOrganization';

export interface ExportMenuItemsProps {
  hideReveal?: boolean;
  onClick: (value: ExportType) => () => void;
  onPinClick?: (value: ExportType) => void;
  pinned?: ExportType[];
}

const ExportMenuItems = ({ onClick, pinned, onPinClick, hideReveal }: ExportMenuItemsProps) => {
  const { hasIntegration, modelType } = useExport();
  const { data: organization } = useOrganization();
  const handlePinClick = (value: ExportType) => () => {
    if (onPinClick) onPinClick(value);
  };

  return (
    <>
      {/* Export to Salesforce */}
      {hasIntegration('salesforce') && (
        <DropdownMenu.Item
          as="div"
          onClick={onClick('salesforce')}
          className="flex flex-row items-center gap-4"
          disabled={organization?.list_write_disabled}
        >
          <SalesforceIcon className="w-5 h-5" />
          <div className="text-sm flex-1">Export to Salesforce</div>
          {pinned && (
            <div className="z-[7]">
              <TogglePinIconButton
                checked={includes(pinned, 'salesforce')}
                onClick={handlePinClick('salesforce')}
              />
            </div>
          )}
        </DropdownMenu.Item>
      )}
      {/* Export to Marketo */}
      {modelType === ModelType.Contact && hasIntegration('marketo') && (
        <DropdownMenu.Item
          as="div"
          onClick={onClick('marketo')}
          className="flex flex-row items-center gap-4"
          disabled={organization?.list_write_disabled}
        >
          <MarketoIcon className="w-5 h-5" />
          <div className="text-sm flex-1">Export to Marketo</div>
          {pinned && (
            <div className="z-[6]">
              <TogglePinIconButton
                checked={includes(pinned, 'marketo')}
                onClick={handlePinClick('marketo')}
              />
            </div>
          )}
        </DropdownMenu.Item>
      )}

      {/* Export to Hubspot */}
      {hasIntegration('hubspot') && (
        <DropdownMenu.Item
          as="div"
          onClick={onClick('hubspot')}
          className="flex flex-row items-center gap-4"
          disabled={organization?.list_write_disabled}
        >
          <HubspotIcon className="w-5 h-5" />
          <div className="text-sm flex-1">Export to Hubspot</div>
          {pinned && (
            <div className="z-[5]">
              <TogglePinIconButton
                checked={includes(pinned, 'hubspot')}
                onClick={handlePinClick('hubspot')}
              />
            </div>
          )}
        </DropdownMenu.Item>
      )}

      {/* Export to CSV */}
      <DropdownMenu.Item
        as="div"
        onClick={onClick('csv')}
        className="flex flex-row items-center gap-4"
        disabled={organization?.list_write_disabled}
      >
        <CsvIcon className="w-5 h-5" />
        <div className="text-sm flex-1">Export to CSV</div>
        {pinned && (
          <div className="z-[4]">
            <TogglePinIconButton
              checked={includes(pinned, 'csv')}
              onClick={handlePinClick('csv')}
            />
          </div>
        )}
      </DropdownMenu.Item>

      {/* Export to list */}
      <DropdownMenu.Item
        as="div"
        onClick={onClick('toList')}
        className="flex flex-row items-center gap-4"
        disabled={organization?.list_write_disabled}
      >
        <AddToListIcon className="w-5 h-5" />
        <div className="text-sm flex-1">Add to list</div>
        {pinned && (
          <div className="z-[3]">
            <TogglePinIconButton
              checked={includes(pinned, 'toList')}
              onClick={handlePinClick('toList')}
            />
          </div>
        )}
      </DropdownMenu.Item>

      {/* Reveal */}
      {!hideReveal && (
        <DropdownMenu.Item
          as="div"
          onClick={onClick('reveal')}
          className="flex flex-row items-center gap-4"
          disabled={organization?.list_write_disabled}
        >
          <EyeIcon className="w-5 h-5" />
          <div className="text-sm flex-1">Show contact info</div>
          {pinned && (
            <div className="z-[2]">
              <TogglePinIconButton
                checked={includes(pinned, 'reveal')}
                onClick={handlePinClick('reveal')}
              />
            </div>
          )}
        </DropdownMenu.Item>
      )}

      {/* See on LinkedIn */}
      <DropdownMenu.Item
        as="div"
        onClick={onClick('linkedin')}
        className="flex flex-row items-center gap-4"
      >
        <LinkedinIcon className="w-5 h-5" />
        <div className="text-sm flex-1">Browse on LinkedIn</div>
        {pinned && (
          <div className="z-[1]">
            <TogglePinIconButton
              checked={includes(pinned, 'linkedin')}
              onClick={handlePinClick('linkedin')}
            />
          </div>
        )}
      </DropdownMenu.Item>
    </>
  );
};

export default ExportMenuItems;
