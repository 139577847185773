import { useCallback, useState, useRef } from 'react';
import { isEmpty, reject } from 'lodash';
import { FilterProps } from './types';
import {
  ContactFilterName,
  CompanyFilterName,
  ContactLocationFilter,
  CompanyLocationFilter,
} from 'types/filters';
import useFilter from './useFilter';
import { getLocationLabel } from './utils';
import LocationSelectFilter from './LocationSelectFilter';
import Inline from 'components/Filter/Inline';
import Button from 'components/Filter/Inline/Button';
import Badges from 'components/Filter/Inline/Badges';
import Icon from 'components/Filter/FilterIcon';
import Label from 'components/Filter/FilterLabel';
import Switch from 'components/Filter/Dropdown/SwitchMenuItem';
import TextField from 'components/Input/TextField';
import classNames from 'classnames';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import CompanyLocationSelect from './CompanyLocationSelect';

const getBadgeProps = (value: any) => {
  const label = getLocationLabel(value);
  const { designation } = value;

  return {
    key: `${designation}-${label}`,
    label,
    exclude: value.exclude,
  };
};

interface LocationFilterProps extends FilterProps {
  badgeDisplayCap?: number;
  isContactRenderDisabled?: boolean;
  isContactsDisabled?: boolean;
  name: ContactFilterName.ContactLocation | CompanyFilterName.CompanyLocation;
}

const LocationFilter = ({
  clearInputOnChange,
  name,
  dense,
  variant,
  badgeDisplayCap,
  isContactRenderDisabled,
  isContactsDisabled,
}: LocationFilterProps) => {
  const placeholder = 'Enter country, state or city';
  const advancedWrapperRef = useRef<HTMLDivElement>(null);

  const [advancedMode, setAdvancedMode] = useState(false);

  const contactCountry = !advancedMode || isContactRenderDisabled || isContactsDisabled;

  const { value, onChange, onClear } = useFilter(
    contactCountry ? name : ContactFilterName.ContactLocation,
  );

  const handleChange = useCallback(
    (value: ContactLocationFilter | CompanyLocationFilter | undefined) => {
      onChange(value);
    },
    [onChange],
  );

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);
      onChange(newValue);
    },
    [onChange, value],
  );

  const handleToggleAdvancedMode = useCallback(() => {
    setAdvancedMode((state) => !state);
  }, []);

  if (variant === 'plain') {
    return (
      <div className="space-y-2">
        <div className="max-h-20 overflow-auto">
          <Badges
            value={value}
            onDelete={handleDelete}
            getBadgeProps={getBadgeProps}
            badgeDisplayCap={badgeDisplayCap}
          />
        </div>
        <LocationSelectFilter
          placeholder={placeholder}
          showIncludeExclude
          value={value}
          onChange={onChange}
          onClose={handleToggleAdvancedMode}
          container={advancedWrapperRef}
          autoFocus
          clearInputOnChange={clearInputOnChange}
          InputComponent={TextField}
          variant={variant}
        />
      </div>
    );
  }

  return (
    <Inline
      value={value}
      onChange={handleChange}
      multiple
      button={({ open }) => (
        <Button
          icon={<Icon name={name} />}
          label={<Label name={contactCountry ? name : 'ContactCountry'} />}
          filled={!isEmpty(value)}
          dense={dense}
          expanded={open}
          onClear={onClear}
        />
      )}
      info={
        <Badges
          className={classNames({
            'px-4 pb-4': !dense,
            'px-4 pb-2': dense,
          })}
          value={value}
          onDelete={handleDelete}
          getBadgeProps={getBadgeProps}
          badgeDisplayCap={badgeDisplayCap}
        />
      }
      menuClassName="max-h-none"
    >
      <div ref={advancedWrapperRef}>
        {!advancedMode && (
          <CompanyLocationSelect
            onChange={onChange}
            clearInputOnChange={clearInputOnChange}
            handleToggleAdvancedMode={handleToggleAdvancedMode}
            value={value}
          />
        )}
        {(advancedMode && (isContactRenderDisabled || isContactsDisabled)) ||
        (advancedMode && name !== CompanyFilterName.CompanyLocation) ? (
          <div className="space-y-4 px-4 py-2">
            <LocationSelectFilter
              label="Country"
              placeholder="Enter country"
              showIncludeExclude
              field="country"
              value={value}
              onChange={onChange}
              onClose={handleToggleAdvancedMode}
              container={advancedWrapperRef}
              autoFocus
              clearInputOnChange
              variant="plain"
            />
            <LocationSelectFilter
              label="State"
              placeholder="Enter state"
              showIncludeExclude
              field="state"
              value={value}
              onChange={onChange}
              onClose={handleToggleAdvancedMode}
              container={advancedWrapperRef}
              clearInputOnChange
              variant="plain"
            />
            <LocationSelectFilter
              label="City"
              placeholder="Enter city"
              showIncludeExclude
              field="city"
              value={value}
              onChange={onChange}
              onClose={handleToggleAdvancedMode}
              container={advancedWrapperRef}
              clearInputOnChange
              variant="plain"
            />
          </div>
        ) : advancedMode && name === CompanyFilterName.CompanyLocation ? (
          <>
            <TextMenuItem className="-mt-2 -mb-0">
              Ensure contacts are located in preferred countries
            </TextMenuItem>
            <LocationSelectFilter
              placeholder="Enter country"
              showIncludeExclude
              field="country"
              value={value}
              onChange={onChange}
              onClose={handleToggleAdvancedMode}
              container={advancedWrapperRef}
              autoFocus
              clearInputOnChange
              InputComponent={TextField}
              // variant="plain"
            />
          </>
        ) : null}
        <Switch
          value={!advancedMode}
          onChange={handleToggleAdvancedMode}
          label1="Normal mode"
          label2="Advanced mode"
        />
      </div>
    </Inline>
  );
};

export default LocationFilter;
