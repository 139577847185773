import { Filters } from './filters';
import {
  EmployeesSizeRange,
  IndustryByCategory,
  Intent,
  JobFunction,
  JobLevel,
  Model,
  RevenueRange,
  TechInstall,
  Location,
  Pagination,
  PaginationResponse,
  SortBy,
  SubIndustry,
  Keyword,
  Industry,
} from 'types';

// filters autocomplete types

export type AutocompleteParams = {
  count?: number;
  index?: number;
  value: string;
};

export type LocationAutocompleteParams = {
  fields?: Array<Location['designation']>;
} & AutocompleteParams;

export type CompanyAutocomplete = {
  country?: string;
  domain?: string;
  dsid: string;
  logo_url?: string;
  name?: string;
};

export type JobAutocomplete = {
  area?: string;
  function: string;
  tag: string;
  title: string;
};

export enum AutocompleteFieldName {
  BusinessKeyword = 'business_keyword',
  Company = 'company',
  Intent = 'intent',
  Job = 'job',
  Location = 'location',
  TechInstall = 'tech_install',
}

export type AutocompleteResponse = {
  [AutocompleteFieldName.BusinessKeyword]: Array<Keyword>;
  [AutocompleteFieldName.Company]: Array<CompanyAutocomplete>;
  [AutocompleteFieldName.Location]: Array<Location>;
  [AutocompleteFieldName.Intent]: Array<Intent>;
  [AutocompleteFieldName.TechInstall]: Array<TechInstall>;
  [AutocompleteFieldName.Job]: Array<JobAutocomplete>;
};

// filters data (options) types

export enum FilterDataFieldName {
  EmployeesSizeRanges = 'employees_size_ranges',
  Industries = 'industries',
  JobFunctions = 'job_functions',
  JobLevels = 'job_levels',
  RevenueRanges = 'revenue_ranges',
}

type IndustryWithSubindustries = {
  sub_category?: Array<SubIndustry>;
} & IndustryByCategory;

export type FilterDataResponse = {
  [FilterDataFieldName.EmployeesSizeRanges]: Array<EmployeesSizeRange>;
  [FilterDataFieldName.Industries]: Array<IndustryWithSubindustries>;
  [FilterDataFieldName.JobFunctions]: Array<JobFunction>;
  [FilterDataFieldName.JobLevels]: Array<JobLevel>;
  [FilterDataFieldName.RevenueRanges]: Array<RevenueRange>;
};

// other search related types

export enum AggregateField {
  CompanyEmployees = 'company_employee_size_range_enum',
  CompanyIndustry = 'company_industry_category',
  CompanyLocationCountry = 'company_location_country',
  CompanyRevenue = 'company_revenue_range_enum',
  JobFunction = 'contact_job_function',
  JobLevel = 'contact_job_level',
}

export type AggregateFilter =
  | Location
  | EmployeesSizeRange
  | Industry
  | JobFunction
  | JobLevel
  | RevenueRange;

export type AggregateSearchPayload = {
  aggregations?: Array<{ field_name: AggregateField; max_size?: number }>;
  filters: Filters;
  pagination: Pagination;
};

export type Constraints = {
  limit?: number;
  max_contacts_per_company: number;
};

export type SearchPayload = {
  constraints?: Constraints;
  filters: Filters;
  pagination: Pagination;
  sort: SortBy;
};

export enum CompaniesSort {
  Domain = 'company_domain',
  FoundedYear = 'company_founded_year',
  Heeadcount = 'company_employee_size_range',
  Industry = 'company_industry_category',
  Name = 'company_name',
  Revenue = 'company_revenue_range',
}

export const companiesSortOptions = [
  {
    value: CompaniesSort.Revenue,
    label: 'Company revenue',
  },
  {
    value: CompaniesSort.Name,
    label: 'Company name',
  },
  {
    value: CompaniesSort.Domain,
    label: 'Company domain',
  },
  {
    value: CompaniesSort.Heeadcount,
    label: 'Company headcount',
  },
  {
    value: CompaniesSort.Industry,
    label: 'Company industry',
  },
];

export enum ContactsSort {
  CompanyIndustry = 'company_industry_category',
  CompanyName = 'company_name',
  FirstName = 'contact_first_name',
  JobLevel = 'contact_job_level_rank',
  JobTitle = 'contact_job_title',
  LastName = 'contact_last_name',
}

export const contactsSortOptions = [
  {
    value: ContactsSort.JobLevel,
    label: 'Contact job level',
  },
  {
    value: ContactsSort.JobTitle,
    label: 'Contact job title',
  },
  {
    value: ContactsSort.FirstName,
    label: 'Contact first name',
  },
  {
    value: ContactsSort.LastName,
    label: 'Contact last name',
  },
  {
    value: ContactsSort.CompanyName,
    label: 'Company name',
  },
  {
    value: ContactsSort.CompanyIndustry,
    label: 'Company industry',
  },
];

export type AggregateData<T> = {
  count: number;
  data: T;
  value: string;
};

export type Aggregate<
  T extends Location | EmployeesSizeRange | Industry | JobFunction | JobLevel | RevenueRange,
> = AggregateData<T>;

export type Aggregations = {
  [K in keyof AggregationResults]: {
    count: number;
    values: AggregationResults[K];
  };
};

export type AggregationResults = {
  [AggregateField.CompanyLocationCountry]?: Array<Aggregate<Location>>;
  [AggregateField.CompanyEmployees]?: Array<Aggregate<EmployeesSizeRange>>;
  [AggregateField.CompanyIndustry]?: Array<Aggregate<IndustryByCategory>>;
  [AggregateField.JobLevel]?: Array<Aggregate<JobLevel>>;
  [AggregateField.JobFunction]?: Array<Aggregate<JobFunction>>;
  [AggregateField.CompanyRevenue]?: Array<Aggregate<RevenueRange>>;
};

export type BaseSearchResponse = {
  hits: Model[];
  pagination: {
    revealed: number;
    totalCount?: number;
  } & PaginationResponse;
};

export type SearchResponse = BaseSearchResponse & {
  aggregation_results?: AggregationResults;
};

export type SearchSettingsResponse = {
  global_exclusion_list?: boolean;
};

export type AggregatesResponse = AggregationResults & {
  count: number;
};

// Define interfaces to represent the response data structure
interface Country {
  country: string;
  country_code_iso2: string;
  designation: string;
  global_region: string;
}

interface SubRegion {
  countries: Country[];
  sub_region: string;
}

export interface Region {
  region: string;
  sub_regions: SubRegion[];
}

export interface IndustryOption {
  id: string;
  label: string;
  value: {
    sub_category: string;
  };
}

export interface IndustryListType {
  id: string;
  label: string;
  options: IndustryOption[];
  value: {
    category: string;
  };
}
