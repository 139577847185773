import { useState, useEffect } from 'react';
import { Button, Modal } from '@demandscience/ui';
import CloseIcon from '@demandscience/ui/icons/x';
import { CompanyIndustryFilter, ExcludableFilter, IndustryByCategory, SubIndustry } from 'types';
import useIndustryOptions from '../Options/useIndustryOptions';
import IndustryList from './IndustryList';
import { OptionWithNestedOptions } from '../types';

interface SelectedOptions {
  [industryId: string]: {
    [optionId: string]: boolean;
  };
}
interface OpenModeProps {
  closeModal: () => void;
  onChange: (value: CompanyIndustryFilter | undefined) => void;
  open: boolean;
  value: CompanyIndustryFilter | undefined;
}

const OpenModal = ({ open, closeModal, value, onChange }: OpenModeProps) => {
  const optionsQuery = useIndustryOptions();
  const { data = [], isFetching, isError } = optionsQuery;

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
  const [initialSelectedOptions, setInitialSelectedOptions] = useState<SelectedOptions>({});

  useEffect(() => {
    if (data.length > 0) {
      const initialSelected = initializeSelectedOptions(value, data);
      setSelectedOptions(initialSelected);
      setInitialSelectedOptions(initialSelected);
    }
  }, [value, data]);

  const onClose = () => {
    // Just close the modal without saving changes, revert to initial selections
    setSelectedOptions(initialSelectedOptions);
    closeModal();
  };

  const onSubmit = () => {
    const formattedData = formatSelectedOptions(selectedOptions, data);
    onChange(formattedData);
    closeModal();
  };

  const onCancel = () => {
    // Revert any changes made since opening the modal
    setSelectedOptions(initialSelectedOptions);
    closeModal();
  };

  return (
    <Modal
      aria-labelledby="industries-modal-title"
      open={open}
      onClose={onClose}
      className="fixed inset-0 flex items-center justify-end p-0 pt-1 sm:max-w-[70%] ml-[20%]"
    >
      <div className="relative w-full h-full max-h-screen bg-white p-4 rounded-lg overflow-auto font-inter">
        <Modal.Title id="industries-modal-title" className="text-lg font-medium text-gray-700">
          Select Industries and Sectors
        </Modal.Title>

        <button
          type="button"
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <CloseIcon size={17} />
        </button>

        {isFetching ? (
          <div className="mt-4">Loading...</div>
        ) : isError ? (
          <div className="mt-4 text-red-500">Error loading data.</div>
        ) : (
          <IndustryList
            data={data}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        )}

        <div className="flex justify-end mt-6 mr-5">
          <Button theme="primary" outline type="button" onClick={onCancel} className="mr-2">
            Cancel
          </Button>
          <Button type="button" onClick={onSubmit}>
            Select
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OpenModal;

function formatSelectedOptions(
  selectedOptions: SelectedOptions,
  data: OptionWithNestedOptions<IndustryByCategory, SubIndustry>[],
): CompanyIndustryFilter {
  const formattedData: CompanyIndustryFilter = [];

  for (const industry of data) {
    const industryId = industry.id;
    const industryOptions = industry.options || [];

    if (selectedOptions[industryId]) {
      const selectedSubCategories = Object.entries(selectedOptions[industryId])
        .filter(([, isSelected]) => isSelected)
        .map(([optionId]) => optionId);

      if (selectedSubCategories.length === industryOptions.length) {
        formattedData.push({
          type: 'IndustryByCategory',
          category: industry.value.category,
        } as IndustryByCategory & ExcludableFilter & { sub_category?: Array<SubIndustry & ExcludableFilter> });
      } else if (selectedSubCategories.length > 0) {
        formattedData.push({
          type: 'IndustryByCategory',
          category: industry.value.category,
          sub_category: selectedSubCategories.map((subCatId) => ({
            sub_category: subCatId,
          })),
        } as IndustryByCategory & ExcludableFilter & { sub_category?: Array<SubIndustry & ExcludableFilter> });
      }
    }
  }

  return formattedData;
}

function initializeSelectedOptions(
  value: CompanyIndustryFilter | undefined,
  data: OptionWithNestedOptions<IndustryByCategory, SubIndustry>[],
): SelectedOptions {
  const selectedOptions: SelectedOptions = {};

  if (!value || value.length === 0) {
    return selectedOptions;
  }

  for (const industry of value) {
    if ('category' in industry) {
      const category = industry.category;
      const industryData = data.find((item) => item.value.category === category);
      if (!industryData) continue;

      const industryId = industryData.id;

      if (industry.sub_category) {
        const subCategoryIds = industry.sub_category.map((subCat) => subCat.sub_category);
        selectedOptions[industryId] = {};
        subCategoryIds.forEach((subCatId) => {
          selectedOptions[industryId][subCatId] = true;
        });
      } else {
        selectedOptions[industryId] = {};
        industryData.options?.forEach((option) => {
          selectedOptions[industryId][option.id] = true;
        });
      }
    }
  }

  return selectedOptions;
}
