export const Banner = () => (
  <div
    style={{
      width: '100%',
      height: '5%',
      background: '#FEF3c7',
      padding: '0px',
      textAlign: 'center',
    }}
  >
    Data loading is in progress, List options will be available shortly.
  </div>
);
