import { ExportDestination, TimePeriod } from './export';
import { AccountBase, ModelType, Organization, PaginationResponse, SearchParams } from './globals';

export enum CreditActivityAction {
  AdminAdd = 'admin_add',
  AdminRemove = 'admin_remove',
  Export = 'export',
  Reveal = 'reveal',
}

export enum CreditActivityContext {
  Admin = 'admin',
  Extension = 'extension',
  Webapp = 'webapp',
}

export type CreditsActivity = {
  account?: AccountBase;
  action: CreditActivityAction;
  action_context: CreditActivityContext;
  created_at: string;
  credits_consumed: number;
  destination?: ExportDestination;
  export_id?: string;
  export_identifier?: string;
  organization: Organization;
  record_type: ModelType;
};

export type CreditsActivityResponse = PaginationResponse & {
  credits: CreditsActivity[];
};

export type CreditsActivityFilter = {
  account_freetext?: string;
  action_contexts?: CreditActivityContext[];
  actions?: CreditActivityAction[];
  date_from?: string;
  date_to?: string;
  destinations?: ExportDestination[];
  record_types?: ModelType[];
  time_period?: TimePeriod;
};

export type SearchCreditsActivityParams = SearchParams<CreditsActivityFilter>;
