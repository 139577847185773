import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import Wrapper from './Wrapper';

export interface InlineProps {
  button: ({
    activeIndex,
    open,
    value,
  }: {
    activeIndex: number | null;
    open: boolean;
    value: any;
  }) => React.ReactNode;
  by?: string;
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
  id?: string;
  info?: React.ReactNode;
  menuClassName?: string;
  multiple?: boolean;
  nested?: boolean;
  onChange: (value: any) => void;
  value: any;
}

const DEFAULT_VALUE: any = []; // this is a workaround for dfault value not to be recreated on each render

const Inline = ({
  id,
  className,
  menuClassName,
  value = DEFAULT_VALUE,
  button,
  info,
  footer,
  children,
  nested,
  multiple,
  onChange,
  by,
}: InlineProps) => {
  return (
    // @ts-expect-error = not sure why multiple prop is causing an error
    <Combobox multiple={multiple} value={value} onChange={onChange} by={by}>
      {(props) => (
        <Wrapper id={id} className={className} open={props.open} outlined={!nested}>
          <Combobox.Button className="cursor-pointer select-none" as="div">
            {button(props)}
            {!props.open && info}
          </Combobox.Button>
          <Combobox.Options hold>
            <div
              className={overrideTailwindClasses(
                classNames('peer max-h-56 overflow-auto', menuClassName),
              )}
            >
              {children}
            </div>
            {footer}
            {!nested && (
              <div
                className={classNames('pb-2 sticky bottom-0', { 'peer-empty:hidden': !footer })}
              />
            )}
          </Combobox.Options>
        </Wrapper>
      )}
    </Combobox>
  );
};

export default Inline;
