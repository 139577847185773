import Heading from 'layouts/Main/Heading';
import Plus from '@demandscience/ui/icons/plus';
import UploadCloud from '@demandscience/ui/icons/upload-cloud';
import CreateListControl from 'components/Lists/CreateListControl';
import { ListFilter } from 'types';
import UploadListControl from './UploadListControl';
import ListsFilters from './ListsFilters';

interface ListsHeaderProps {
  filters?: ListFilter;
  onChange: (value: any) => void;
  vaultLoading?: boolean;
}

const ListsHeader = ({ filters, onChange, vaultLoading }: ListsHeaderProps) => (
  <>
    <Heading>Lists</Heading>
    <div className="mb-4 md:mt-6 md:mb-20 flex-shrink-0 flex flex-col md:items-center md:flex-row space-y-2 md:space-y-0 md:space-x-4">
      <ListsFilters value={filters} onChange={onChange} />
      <div className="grow flex items-center justify-end">
        <CreateListControl
          disabled={vaultLoading}
          borderless
          leftIcon={<Plus className="w-5 h-5" />}
          className="ml-5"
        >
          Create list
        </CreateListControl>
        <UploadListControl
          disabled={vaultLoading}
          leftIcon={<UploadCloud className="w-5 h-5" />}
          className="ml-5"
        >
          Upload list
        </UploadListControl>
      </div>
    </div>
  </>
);

export default ListsHeader;
