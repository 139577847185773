import React, { useState } from 'react';
import { Badge } from '@demandscience/ui';
import classNames from 'classnames';
import { Size } from '@demandscience/ui/src/types';

interface LocationBadgeProps {
  hasSubItems?: boolean;
  isRegion?: boolean;
  isSelected: boolean;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onDelete?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  size?: Size;
}

const LocationBadge: React.FC<LocationBadgeProps> = ({
  label,
  isSelected,
  hasSubItems,
  onClick,
  onDelete,
  size = 'md',
  isRegion = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (hasSubItems) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const customStyle =
    isSelected && isRegion
      ? {
          backgroundColor: '#0066FC',
          borderColor: '#0066FC',
          color: '#FFFFFF',
        }
      : {
          backgroundColor: '#F1F5F9',
        };

  if (isHovered && hasSubItems && !isSelected) {
    customStyle.backgroundColor = '#D1FAE5'; // Light blue when hovered
    customStyle.borderColor = '#D1FAE5';
  }
  const deleteIconClassName = isRegion ? 'hidden' : undefined;

  return (
    <Badge
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIconClassName={deleteIconClassName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames('cursor-pointer')}
      size={size}
      style={customStyle}
    />
  );
};

export default LocationBadge;
