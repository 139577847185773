import { DropdownMenu } from '@demandscience/ui';
import { useCallback } from 'react';
import { ExportData, ListOrigin, ListRecordsAction } from 'types';
import useActions from './useActions';
import useOrganization from 'components/Organization/useOrganization';
interface ActionMenuItemsProps {
  exportData: ExportData;
}

const ActionMenuItems = ({ exportData }: ActionMenuItemsProps) => {
  const actions = useActions();
  const { data: organization } = useOrganization();
  const vaultLoading = organization?.list_write_disabled;

  const handleAction = useCallback(
    (act: ListRecordsAction) => () => {
      actions.action(act, exportData);
    },
    [actions, exportData],
  );

  if (!actions) {
    return null;
  }

  return (
    <>
      {actions.editable && actions.listOrigin === ListOrigin.Search && (
        <DropdownMenu.Item
          as="div"
          disabled={vaultLoading}
          onClick={handleAction(ListRecordsAction.Move)}
        >
          Move to another list
        </DropdownMenu.Item>
      )}
      <DropdownMenu.Item
        as="div"
        disabled={vaultLoading}
        onClick={handleAction(ListRecordsAction.Copy)}
      >
        Copy to another list
      </DropdownMenu.Item>
      {actions.editable && actions.listOrigin === ListOrigin.Search && (
        <DropdownMenu.Item
          as="div"
          disabled={vaultLoading}
          onClick={handleAction(ListRecordsAction.Remove)}
        >
          Remove from list
        </DropdownMenu.Item>
      )}
    </>
  );
};

export default ActionMenuItems;
