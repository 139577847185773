import isEmpty from 'lodash/isEmpty';

import { Button, DataGrid, Link } from '@demandscience/ui';
import UserPlusIcon from '@demandscience/ui/icons/user';

import Filters from './Filters';
import InviteControl from './InviteControl';

import tableColumns from './tableColumns';
import { RowItem } from '@demandscience/ui/dist/types';
import { MembersResponse, OrganizationsFilter } from 'types';
import useDataGridSearch from 'components/DataGrid/useDataGridSearch';
import useMembersSearch from './useMembersSearch';
import MembersSkeleton from './Skeletons/MembersSkeleton';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import Paragraph from 'components/Typography/Paragraph';
import { useCallback } from 'react';
import useModal from 'hooks/useModal';
import { TeamDownload } from './DownloadTeam';
const defaultParams = {
  index: 0,
  count: 25,
  sort: { by: 'last_signin_at', descending: true },
};

const getRowClassName = () => 'cursor-pointer';

interface MembersListProps {
  onRowClick: (row: any) => void;
}

const MembersList: React.FC<MembersListProps> = ({ onRowClick }) => {
  const { params, handleFilterChange, handlePageChange, handleSortChange, query } =
    useDataGridSearch<OrganizationsFilter, MembersResponse>(useMembersSearch, defaultParams);
  const { data, isLoading, isFetching, isError } = query;
  const { open, openModal, closeModal } = useModal();

  let children;

  const handleSeeAll = useCallback(() => {
    handleFilterChange({});
  }, [handleFilterChange]);

  if (isError) {
    children = <div className="text-sm text-error-500">Unexpected error loading users list</div>;
  } else if (isLoading || (isEmpty(data.members) && isFetching)) {
    children = <MembersSkeleton count={5} />;
  } else if (isEmpty(data.members) && !isFetching) {
    children = (
      <EmptyResults
        message="No team member found matching your criteria"
        illustration={<Illustration.Absurd2 className="w-36 h-36 mb-6" />}
        className="mt-36"
      >
        <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
          <Link onClick={handleSeeAll}>See all team members</Link>
        </Paragraph>
      </EmptyResults>
    );
  } else {
    const { members: rows, count } = data || {};

    children = (
      <div className="flex-1 isolate">
        <DataGrid
          columns={tableColumns}
          rows={rows as RowItem[]}
          onRowClick={onRowClick}
          loading={isFetching}
          uniqueIdentifier="username"
          disableInternalComputation
          sortingMode="server"
          onSortModelChange={handleSortChange}
          containerClassName="overflow-visible"
          rowClassName={getRowClassName}
          // pagination
          hideFooterRowCount
          page={params.index + 1}
          rowsPerPage={params.count}
          count={count}
          onPageChange={handlePageChange}
          paginationVariant="combined"
        />

        {isEmpty(rows) && !isFetching && (
          <p className="text-gray-500 py-4 text-center">No record found</p>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="mb-4 md:mt-5 md:mb-16 flex-shrink-0 flex flex-col md:items-center md:flex-row space-y-2 md:space-y-0 md:space-x-4">
        <Filters value={params.filter} onChange={handleFilterChange} />
        <div className="grow flex items-center justify-end">
          <Button
            className="whitespace-nowrap mr-4"
            theme="primary"
            size="md"
            type="button"
            onClick={openModal}
          >
            Download team
          </Button>
          {/* @ts-expect-error demandscience-ui issue */}
          <InviteControl size="md" leftIcon={<UserPlusIcon className="h-5 w-5" />}>
            Invite team member
          </InviteControl>
        </div>
      </div>
      {children}
      <TeamDownload open={open} onClose={closeModal}></TeamDownload>
    </>
  );
};

export default MembersList;
