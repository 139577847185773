import { forwardRef } from 'react';
import { Model, ModelId, ModelType, Contact, Company, RowLayout, ViewLayout } from 'types';
import { map } from 'lodash';
import ContactRow from './ContactRow';
import CompanyRow from './CompanyRow';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import useCustomization from 'components/SearchCustomization/useCustomization';

interface DataGridListLayoutProps {
  dense?: boolean;
  footer?: React.ReactNode;
  handleDetailsShow: (row: Model) => () => void;
  handleRowSelect: (row: Model) => (selected: boolean) => void;
  isSelected: (row: ModelId, index: number) => boolean;
  kind: ModelType;
  rowLayout?: RowLayout;
  rows: Model[];
  selectedRow?: Model;
  selectionDisabled?: boolean;
}

const DataGridListLayout = forwardRef<HTMLUListElement, DataGridListLayoutProps>(
  (
    {
      kind,
      rows,
      isSelected,
      selectionDisabled,
      selectedRow,
      handleRowSelect,
      handleDetailsShow,
      rowLayout,
      footer,
      dense,
    },
    ref,
  ) => {
    const RowComponent = kind === ModelType.Contact ? ContactRow : CompanyRow;

    const location = useLocation();
    const isListRecordsPage = location.pathname.startsWith('/lists');

    const { layout } = useCustomization();

    return (
      <ul
        className={classNames(
          'py-1 overflow-y-auto grow',
          layout.view === ViewLayout.Table || isListRecordsPage ? 'pb-56' : '',
        )}
        ref={ref}
      >
        {map(rows, (row, index) => (
          <RowComponent
            active={selectedRow?.dsid === row.dsid}
            dense={dense}
            key={row.dsid}
            row={row as Contact & Company}
            selected={isSelected(row, index)}
            selectionDisabled={selectionDisabled}
            onSelect={handleRowSelect(row)}
            onClick={handleDetailsShow(row)}
            rowLayout={rowLayout}
            lastItem={index + 1 === rows?.length}
            index={index}
            nbOfRows={rows.length}
          />
        ))}
        <li className="flex justify-center">{footer}</li>
      </ul>
    );
  },
);

DataGridListLayout.displayName = 'DataGridListLayout';

export default DataGridListLayout;
