/* eslint-disable no-console */
import { isAfter } from 'date-fns';
import { Notification } from 'types';
import { useLocalStorage } from 'react-use';
import { useEffect, useState } from 'react';
import { filter, flatten, map } from 'lodash';
import { Toast, toast } from '@demandscience/ui';
import useNotifications from './useNotifications';
import useNotificationsContext from './useNotificationsContext';
import AutoDownloadBookmarks from 'components/BookMark/AutoDownloadBookmarks';
import { useLocation } from 'react-router-dom';

// Paths to ignore
const IGNORED_PATHS = ['/auth', '/welcome'];

const UnreadNotificationsToasts = () => {
  const [localUnreadNotifications, setLocalUnreadNotifications] = useLocalStorage<Notification[]>(
    'KlarityUnreadNotifications',
    [],
  );
  const [newUnreadNotifications, setNewUnreadNotifications] = useState<Notification[]>([]);
  const { openNotifications } = useNotificationsContext();

  const { pathname } = useLocation();

  const {
    query: { data },
    markAsRead,
  } = useNotifications();

  const notifications = flatten(map(data?.pages, 'notifications'));
  const unreadNotifications = filter(notifications, ({ read_at }) => !read_at);

  useEffect(() => {
    if (!newUnreadNotifications.length) {
      return;
    }
    toast({
      subtitle: 'You have unread notification(s)',
      duration: Infinity,
      showCloseButton: true,
      position: 'bottom-left',
      containerClassName: 'ml-20',
      link: 'View notifications',
      closeOnLinkClick: true,
      onLinkClicked: openNotifications,
    });
  }, [newUnreadNotifications, openNotifications]);

  for (const path of IGNORED_PATHS) {
    if (pathname.startsWith(path)) {
      return null;
    }
  }

  if (unreadNotifications.length) {
    if (!localUnreadNotifications?.length) {
      setNewUnreadNotifications(unreadNotifications);
      setLocalUnreadNotifications(unreadNotifications);
    } else {
      const newUnreads = unreadNotifications.filter((notification) =>
        isAfter(
          new Date(notification.created_at),
          new Date(localUnreadNotifications![0].created_at),
        ),
      );

      if (newUnreads.length > 0) {
        setNewUnreadNotifications(newUnreads);
        setLocalUnreadNotifications(unreadNotifications);
      }
    }
  }
  return (
    <>
      <Toast position="top-right" />
      <AutoDownloadBookmarks unreadNotifications={unreadNotifications} markAsRead={markAsRead} />
    </>
  );
};

export default UnreadNotificationsToasts;
