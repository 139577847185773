/* eslint-disable no-console */
import { downloadExportfromNotification } from 'api/bookmark';
import { useEffect, useRef } from 'react';
import { Notification } from 'types';

interface AutoDownloadBookmarksProps {
  markAsRead: {
    mutate: (id: string) => void;
  };
  unreadNotifications: Notification[];
}

const AutoDownloadBookmarks = ({ unreadNotifications, markAsRead }: AutoDownloadBookmarksProps) => {
  const processedNotificationIds = useRef<Set<string>>(new Set());
  const prevUnreadNotificationIds = useRef<Set<string>>(new Set());

  useEffect(() => {
    const currentNotificationIds = new Set(unreadNotifications.map((n) => n.id));

    // Identify new notifications
    const newNotificationIds = [...currentNotificationIds].filter(
      (id) => !prevUnreadNotificationIds.current.has(id),
    );

    // Update previous notification IDs
    prevUnreadNotificationIds.current = currentNotificationIds;

    // Filter new, unprocessed notifications
    const newNotifications = unreadNotifications.filter(
      (notification) =>
        newNotificationIds.includes(notification.id) &&
        notification.type === 'bookmark_export' &&
        notification.event === 'download' &&
        !processedNotificationIds.current.has(notification.id),
    );

    if (newNotifications.length === 0) {
      return;
    }

    newNotifications.forEach((notification) => {
      // Add to processed IDs before starting download
      processedNotificationIds.current.add(notification.id);

      const bookmarkExportId = notification.details?.bookmark_export_id;
      const filename = notification.details?.file_name || 'export-data.csv';

      if (bookmarkExportId) {
        downloadExportfromNotification(bookmarkExportId, filename)
          .then(() => {
            // Mark the notification as read after successful download
            markAsRead.mutate(notification.id);
          })
          .catch((error) => {
            console.error('Failed to download bookmark export:', error);
            // Remove from processed IDs to allow retry
            processedNotificationIds.current.delete(notification.id);
          });
      }
    });
  }, [unreadNotifications, markAsRead]);

  return null;
};

export default AutoDownloadBookmarks;
