import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { isEmpty } from 'lodash';
import LocationBadge from 'components/Filter/Inline/LocationBadge';
import { CompanyLocationFilter, ContactLocationFilter, LocationFilter } from 'types';
import LocationSelectFilter from './LocationSelectFilter';
import TextField from 'components/Input/TextField';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import useCompanyRegion from './useCompanyRegion';

interface Country {
  id: string;
  name: string;
}

interface SubRegion {
  countries?: Country[];
  id: string;
  name: string;
  subRegions?: SubRegion[];
}

interface Region {
  countries?: Country[];
  id: string;
  name: string;
  subRegions?: SubRegion[];
}

interface CompanyLocationSelectProps {
  clearInputOnChange: boolean | undefined;
  handleToggleAdvancedMode: () => void;
  onChange: (value: LocationFilter[]) => void;
  value: CompanyLocationFilter | ContactLocationFilter | undefined;
}

const transformApiDataToRegions = (apiData: any[]): Region[] => {
  const transformSubRegions = (subRegionsData: any[]): SubRegion[] => {
    return subRegionsData.map((subRegionData) => ({
      id: subRegionData.sub_region
        ? subRegionData.sub_region.toLowerCase().replace(/\s+/g, '-')
        : '',
      name: subRegionData.sub_region || 'Unknown Sub-Region',
      countries: subRegionData.countries
        ? subRegionData.countries.map((countryData: any) => ({
            id: countryData.country_code_iso2
              ? countryData.country_code_iso2.toLowerCase()
              : countryData.country.toLowerCase().replace(/\s+/g, '-'),
            name: countryData.country,
          }))
        : undefined,
      subRegions: subRegionData.sub_regions
        ? transformSubRegions(subRegionData.sub_regions)
        : undefined,
    }));
  };

  return apiData.map((regionData) => ({
    id: regionData.region ? regionData.region.toLowerCase().replace(/\s+/g, '-') : '',
    name: regionData.region || 'Unknown Region',
    countries: regionData.countries
      ? regionData.countries.map((countryData: any) => ({
          id: countryData.country_code_iso2
            ? countryData.country_code_iso2.toLowerCase()
            : countryData.country.toLowerCase().replace(/\s+/g, '-'),
          name: countryData.country,
        }))
      : undefined,
    subRegions: regionData.sub_regions ? transformSubRegions(regionData.sub_regions) : undefined,
  }));
};

const CompanyLocationSelect: React.FC<CompanyLocationSelectProps> = ({
  onChange,
  clearInputOnChange,
  handleToggleAdvancedMode,
  value,
}) => {
  const [selectedLocations, setSelectedLocations] = useState<LocationFilter[]>(value || []);
  const [selectedRegionId, setSelectedRegionId] = useState<string | null>(null);
  const [expandedSubRegionId, setExpandedSubRegionId] = useState<string | null>(null);
  const [removedSubRegionIds, setRemovedSubRegionIds] = useState<string[]>([]);
  const advancedWrapperRef = useRef<HTMLDivElement>(null);
  const [hideRegion, setHideRegion] = useState(false);

  const { data: apiData, error, isError } = useCompanyRegion();

  const regionsData = useMemo(() => {
    if (apiData) {
      return transformApiDataToRegions(apiData);
    } else {
      return [];
    }
  }, [apiData]);

  const collectCountries = useCallback((region: Region | SubRegion): Country[] => {
    let countries: Country[] = [];
    if (region.countries) {
      countries = countries.concat(region.countries);
    }
    if (region.subRegions) {
      region.subRegions.forEach((subRegion) => {
        countries = countries.concat(collectCountries(subRegion));
      });
    }
    return countries;
  }, []);

  const findSubRegionById = useCallback(
    (region: Region | SubRegion, subRegionId: string): SubRegion | undefined => {
      if (region.subRegions) {
        for (const subRegion of region.subRegions) {
          if (subRegion.id === subRegionId) {
            return subRegion;
          } else {
            const result = findSubRegionById(subRegion, subRegionId);
            if (result) return result;
          }
        }
      }
      return undefined;
    },
    [],
  );

  useEffect(() => {
    if (value && value.length > 0 && regionsData.length > 0) {
      const regionName = value[0].global_region;
      if (regionName) {
        const region = regionsData.find((r) => r.name.toLowerCase() === regionName.toLowerCase());
        if (region) {
          setSelectedRegionId(region.id);
          const countryCodes = value
            .filter((loc) => loc.designation === 'country' && loc.country_code_iso2)
            .map((loc) => loc.country_code_iso2!.toLowerCase());
          if (countryCodes.length > 0) {
            const findSubRegionWithCountries = (subRegions: SubRegion[]): string | null => {
              for (const subRegion of subRegions) {
                const subRegionCountryCodes = collectCountries(subRegion).map((c) =>
                  c.id.toLowerCase(),
                );
                if (countryCodes.every((code) => subRegionCountryCodes.includes(code))) {
                  return subRegion.id;
                }
                if (subRegion.subRegions) {
                  const nestedResult = findSubRegionWithCountries(subRegion.subRegions);
                  if (nestedResult) return nestedResult;
                }
              }
              return null;
            };
            const subRegionId = findSubRegionWithCountries(region.subRegions || []);
            if (subRegionId) {
              setExpandedSubRegionId(subRegionId);
            }
          }
        }
      }
    }
  }, [value, regionsData, collectCountries]);

  const handleRegionSelect = (regionId: string) => {
    if (selectedRegionId === regionId) {
      setSelectedRegionId(null);
      setExpandedSubRegionId(null);
      setRemovedSubRegionIds([]);
      setSelectedLocations([]);
      onChange([]);
    } else {
      setSelectedRegionId(regionId);
      setExpandedSubRegionId(null);
      setRemovedSubRegionIds([]);
      const region = regionsData.find((r) => r.id === regionId);
      if (!region) return;
      const countries = collectCountries(region);
      const onChangeCountries: LocationFilter[] = countries.map((country) => ({
        city: '',
        country: country.name,
        country_code_iso2: country.id.toUpperCase(),
        county: '',
        designation: 'country',
        global_region: region.name,
        state: '',
      }));
      setSelectedLocations(onChangeCountries);
      onChange(onChangeCountries);
    }
  };

  const handleRegionRemove = (regionId: string) => {
    if (selectedRegionId === regionId) {
      setSelectedRegionId(null);
      setExpandedSubRegionId(null);
      setRemovedSubRegionIds([]);
      setSelectedLocations([]);
      onChange([]);
    }
  };

  const handleSubRegionClick = (subRegion: SubRegion) => {
    setExpandedSubRegionId(subRegion.id);
    const region = regionsData.find((r) => r.id === selectedRegionId);
    if (!region) return;
    const subRegionCountries = collectCountries(subRegion);
    const onChangeCountries: LocationFilter[] = subRegionCountries.map((country) => ({
      city: '',
      country: country.name,
      country_code_iso2: country.id.toUpperCase(),
      county: '',
      designation: 'country',
      global_region: region.name,
      state: '',
    }));
    setSelectedLocations(onChangeCountries);
    onChange(onChangeCountries);
  };

  const handleSubRegionRemove = (e: React.MouseEvent, subRegionId: string) => {
    e.stopPropagation();
    setRemovedSubRegionIds((prevRemoved) => [...prevRemoved, subRegionId]);
    if (expandedSubRegionId === subRegionId) {
      setExpandedSubRegionId(null);
    }
    const region = regionsData.find((r) => r.id === selectedRegionId);
    if (!region) return;
    const subRegion = findSubRegionById(region, subRegionId);
    if (!subRegion) return;
    const subRegionCountries = collectCountries(subRegion);
    setSelectedLocations((prevLocations) => {
      const updatedLocations = prevLocations.filter(
        (loc) =>
          loc.designation !== 'country' ||
          !subRegionCountries.some(
            (country) => country.id.toUpperCase() === loc.country_code_iso2?.toUpperCase(),
          ),
      );
      onChange(updatedLocations);
      return updatedLocations;
    });
  };

  const handleCountryRemove = (e: React.MouseEvent, countryCode: string) => {
    e.stopPropagation();
    setSelectedLocations((prevLocations) => {
      const updatedLocations = prevLocations.filter(
        (loc) =>
          !(
            loc.designation === 'country' &&
            loc.country_code_iso2 &&
            loc.country_code_iso2.toUpperCase() === countryCode.toUpperCase()
          ),
      );
      onChange(updatedLocations);
      return updatedLocations;
    });
  };

  const isCountryNotInSelectedRegions = (value: LocationFilter[]): boolean => {
    const selectedCountryCodes = selectedLocations
      .filter((loc) => loc.designation === 'country' && loc.country_code_iso2)
      .map((loc) => loc.country_code_iso2!.toLowerCase());
    return value.some((location) => {
      if (location.designation === 'country' && location.country_code_iso2) {
        const countryCode = location.country_code_iso2.toLowerCase();
        return !selectedCountryCodes.includes(countryCode);
      }
      return false;
    });
  };

  const onManualSelect = (value: LocationFilter[]) => {
    setSelectedLocations(value);
    onChange(value);
    if (isCountryNotInSelectedRegions(value)) {
      setHideRegion(true);
    }
  };

  if (isError) {
    return <div className="text-sm text-red-500">Error: {(error as Error).message}</div>;
  }

  return (
    <div className="m-4">
      <div className="region-selection">
        {regionsData.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {!hideRegion ? (
              regionsData.map((region) => (
                <LocationBadge
                  key={region.id}
                  label={region.name}
                  isSelected={selectedRegionId === region.id}
                  hasSubItems={!isEmpty(region.subRegions)}
                  onClick={() => handleRegionSelect(region.id)}
                  onDelete={
                    selectedRegionId === region.id ? () => handleRegionRemove(region.id) : undefined
                  }
                  isRegion={true}
                />
              ))
            ) : (
              <LocationBadge
                key="Custom"
                label="Custom search"
                isSelected
                hasSubItems={false}
                isRegion={true}
              />
            )}
          </div>
        ) : (
          <div>Loading regions...</div>
        )}
        {value?.length && <DividerMenuItem className="p-1" />}
      </div>

      {!hideRegion && selectedRegionId && !expandedSubRegionId && (
        <div className="flex flex-wrap gap-2 region-details mt-4">
          {(() => {
            const region = regionsData.find((r) => r.id === selectedRegionId);
            if (!region) return null;
            return region.subRegions
              ?.filter((subRegion) => !removedSubRegionIds.includes(subRegion.id))
              .map((subRegion) => (
                <div key={subRegion.id} className="sub-region">
                  <LocationBadge
                    label={subRegion.name}
                    isSelected={false}
                    hasSubItems={!isEmpty(subRegion.subRegions) || !isEmpty(subRegion.countries)}
                    onClick={() => handleSubRegionClick(subRegion)}
                    onDelete={(e) => handleSubRegionRemove(e, subRegion.id)}
                    size="sm"
                  />
                </div>
              ));
          })()}
        </div>
      )}

      {expandedSubRegionId && (
        <div className="flex flex-wrap gap-2 mt-4">
          {(() => {
            const region = regionsData.find((r) => r.id === selectedRegionId);
            if (!region) return null;
            const subRegion = findSubRegionById(region, expandedSubRegionId);
            if (!subRegion) return null;
            const subRegionCountryIds = collectCountries(subRegion).map((country) =>
              country.id.toUpperCase(),
            );
            const countriesInValue = selectedLocations.filter(
              (loc) =>
                loc.designation === 'country' &&
                loc.country_code_iso2 &&
                subRegionCountryIds.includes(loc.country_code_iso2.toUpperCase()),
            );
            return countriesInValue.map((loc) => (
              <LocationBadge
                key={loc.country_code_iso2 || loc.country}
                label={loc.country || ''}
                isSelected={false}
                hasSubItems={false}
                onDelete={(e) => handleCountryRemove(e, loc.country_code_iso2 || '')}
                size="sm"
              />
            ));
          })()}
        </div>
      )}

      <div style={{ marginTop: '20px', marginLeft: '-12px' }}>
        <LocationSelectFilter
          placeholder="Search countries, cities, regions"
          showIncludeExclude
          value={selectedLocations}
          onChange={onManualSelect}
          onClose={handleToggleAdvancedMode}
          container={advancedWrapperRef}
          autoFocus
          lazyLoad
          clearInputOnChange={clearInputOnChange}
          InputComponent={TextField}
        />
      </div>
    </div>
  );
};

export default CompanyLocationSelect;
