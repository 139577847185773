import { useQuery } from '@tanstack/react-query';
import { fetchCountryFilters } from 'api/search';

const useCompanyRegion = () => {
  const query = useQuery(['companyRegion'], () => fetchCountryFilters(), {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return query;
};

export default useCompanyRegion;
